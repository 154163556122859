import React, { useEffect, useState } from "react";
import IFrame from "react-iframe";

import Modal from "react-bootstrap/Modal";

import "./ResolveContainer.css";

import xIcon from "../Assets/Icon/x@2x.png";

import {
  // rxTextFromDS,
  fullReferText,
  rxTextFromPDF,
} from "../DataFiles/rxTexts";
import {
  medsForMen,
  betterWeightForLife,
  baskNdcArray,
  hallandaleMeds,
} from "../DataFiles/companyDrugs";
import { routerMap } from "../utils/router";
import { switchFullDS } from "../Components/prescription/utils/favoriteUtils.js";
import PrescriptionSelect from "../Components/prescription/PrescriptionSelect.js";
import CollapsibleContent from "../Components/CollapsibleContent.js";

const ResolveContainer = (props) => {
  const {
    handleNotesChange,
    prescriptionSent,
    setPrescriptionSent,
    patient,
    updatePatient,
    setCurrentVisit,
    visit,
    user,
    admin,
    note,
    changeTab,
    setVisitResolutionText,
    updateVisit,
    isAdminResolve,
    setIsAdminResolve,
    // labCompanies,
    hioOutcome,
    handleResolveHio,
    handleHioNoteChange,
    handleHioOutcomeChange,
    hioResolve,
    setHioResolve,
    idPhotos,
    visitInternalChat,
    isPatientLoading,
    setIsPatientLoading,
    switchVideoDone,
    isHourly,
  } = props;
  const [showDosespot, setShowDosespot] = useState(false);
  const [dosespotURL, setDosespotURL] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pdfEmailError, setPdfEmailError] = useState("");
  const [showNoteBox, setShowNoteBox] = useState(false);
  const [canSaveNote, setCanSaveNote] = useState(false);
  const [showDrugForm, setShowDrugForm] = useState(false);
  const [pdfContent, setPdfContent] = useState({});
  const [selectedDrug, setSelectedDrug] = useState({});
  const [videoResult, setVideoResult] = useState("");
  const [isInvalidOpen, setIsInvalidOpen] = useState(false);
  const [currentDropdown, setCurrentDropdown] = useState("");
  // const [isSaving, setSavingState] = useState(false);
  // const [isSavedSuccess, setSavedSuccessState] = useState(false);
  const [medSelectionCollapsibleState, setMedSelectionCollapsibleState] =
    useState(true);
  // const [hasBeenReferred, setHasBeenReferred] = useState(visit.isRefer);

  const token = localStorage.getItem("jwt");

  const {
    sendEmailPdf,
    sendCovidPdf,
    sendEmailPdfVar,
    sendEmailPdfRespiri,
    sendInvalidVideo,
    createDropboxPDF,
    // sendHallandaleRx,
    sendS3Pdf,
    sendCanadaPdf,
    sendJasePdf,
    sendHallandalePdf,
    sendUpliftPdf,
    sendLetterPdf,
    sendDaybreakPdf,
    sendWebhooks,
    prescribeClick,
    getPrescriptionSent,
    updateDocTime,
    getPrescriptionSentDSFull,
    logEvent,
  } = routerMap;

  const mainAdminId =
    process.env.REACT_APP_STAGE === "prod"
      ? "5f923ff775230b0008abacf4"
      : "5e8d4b904e3f47f93c07b01c";
  const dsUrl = dosespotURL ? dosespotURL : "http://www.dosespot.com";

  const patientPreferenceCompanies = [
    "bask",
    "smartDoctors",
    "betterWeightForLife",
    "jase",
    "myoRx",
    "lifebac",
    "bodygoodstudio",
    "motivated",
    "mitochondria",
    "poshdoc",
  ];

  const customPrefCompanies = ["revyv", "rxCompoundStore", "upForLove"];

  const {
    _id,
    company,
    visitType,
    currentDoc,
    pharmacyId,
    formObj: { patientPreference, patientPref, testToTreat },
    note: visitNote,
  } = visit;

  const formattedPatientPref = customPrefCompanies.includes(company) ? (
    company === "upForLove" ? (
      Array.isArray(patientPref) ? (
        patientPref.map((pref) => {
          return (
            <span key={pref.name}>
              <strong>Drug:</strong> <span>{pref.name}</span>,{" "}
              <strong>Strength:</strong> <span>{pref.strength}</span>,{" "}
              <strong>Total Pills:</strong> <span>{pref.quantity}</span>,{" "}
              <strong>Refills:</strong> <span>{pref.refills}</span>
            </span>
          );
        })
      ) : (
        <span>
          <strong>Drug:</strong> <span>{patientPref.name}</span>,{" "}
          <strong>Strength:</strong> <span>{patientPref.strength}</span>,{" "}
          <strong>Total Pills:</strong> <span>{patientPref.quantity}</span>,{" "}
          <strong>Refills:</strong> <span>{patientPref.refills}</span>
        </span>
      )
    ) : (
      <span>
        {patientPref.planName && (
          <span>
            Plan Name: <strong>{patientPref.planName}</strong>,{" "}
          </span>
        )}
        <strong>Drug:</strong>{" "}
        <span>{patientPref.drug ? patientPref.drug : patientPref.name}</span>,{" "}
        <strong>Strength:</strong> <span>{patientPref.strength}</span>,{" "}
        <strong>Total Pills:</strong>{" "}
        <span>
          {patientPref.totalPills
            ? patientPref.totalPills
            : patientPref.quantity}
        </span>
        , <strong>Refills:</strong> <span>{patientPref.refills}</span>
      </span>
    )
  ) : null;

  const switchNameForBask = (pref) => {
    const foundName = baskNdcArray.find(
      (med) => med.sku === pref.packageNDC
    )?.name;
    const switchName = foundName ? foundName : pref.name;
    return switchName;
  };

  const switchNameForHallandale = (pref) => {
    const foundName = hallandaleMeds.find(
      (med) => med.sku === pref.medId
    )?.name;
    return foundName || pref.name;
  };

  const switchPrefName = (pref) => {
    switch (true) {
      case /^bask/.test(company):
        return <td>{switchNameForBask(pref)}</td>;
      case company === "mitochondria":
      case company === "poshdoc":
        return <td>{switchNameForHallandale(pref)}</td>;
      default:
        return <td>{pref.name}</td>;
    }
  };

  const patientPreferences =
    patientPreferenceCompanies.includes(company) || /^bask/.test(company) ? (
      Array.isArray(patientPreference) ? (
        <>
          <tr className="tableRowBorder">
            <td>Med</td>
            {(!/^bask/.test(company) || !/weightloss/i.test(visitType)) && (
              <>
                <td>Strength</td>
                <td>Quantity</td>
                <td>Refills</td>
                <td>Pills per Dose</td>
                <td>Frequency</td>
              </>
            )}
          </tr>
          {patientPreference.map((pref) => {
            return (
              <tr className="tableRowBorder" key={pref.name}>
                {switchPrefName(pref)}
                {(!/^bask/.test(company) || !/weightloss/i.test(visitType)) && (
                  <>
                    <td>{pref.strength}</td>
                    <td>{pref.quantity}</td>
                    <td>{pref.refills}</td>
                    <td>{pref.pillsPerDose}</td>
                    <td>{pref.frequency}</td>
                  </>
                )}
              </tr>
            );
          })}
        </>
      ) : (
        Object.keys(patientPreference).map((key) => {
          return (
            <span key={key}>
              <strong>{key}</strong>: {patientPreference[key]}{" "}
            </span>
          );
        })
      )
    ) : null;

  const handleNotes = (e) => {
    handleNotesChange(e.target.value);
  };

  const handleSaveNote = async () => {
    try {
      const now = new Date();
      const writer = `${user.firstName} ${user.lastName}`;
      const newNote = `${now}:noteTime ${writer}:noteWriter ${note}:noteBody`;
      const data = {
        visitUpdate: {
          note: [...visitNote, newNote],
          isRefer: !prescriptionSent,
        },
      };
      setIsPatientLoading(true);
      if (isHourly) {
        await updateDocTime(token);
      }
      await logEvent(token, "saveNote", {
        userId: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        visitType: visitType,
        company: visit.company,
        masterId: visit.masterId,
      });
      await sendWebhooks(token, patient._id, _id);
      await updateVisit(_id, data);
      setIsPatientLoading(false);
      changeTab("messages");
    } catch (err) {
      console.log(err);
      setIsPatientLoading(false);
    }
  };

  const updateVisitPharmacy = async (pharmacyId) => {
    try {
      const data = {
        visitUpdate: {
          pharmacyId,
        },
      };
      await updateVisit(_id, data);
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const handleSaveNoteAndResolve = async () => {
    try {
      await logEvent(token, "saveNote", {
        userId: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        visitType: visitType,
        company: visit.company,
        masterId: visit.masterId,
      });
      const now = new Date();
      const writer = `${user.firstName} ${user.lastName}`;
      const newNote = `${now}:noteTime ${writer}:noteWriter ${note}:noteBody`;
      const closingDoc = currentDoc || mainAdminId;
      const data = isAdminResolve
        ? {
            visitUpdate: {
              status: "resolved",
              note: [...visitNote, newNote],
              currentDoc: null,
              closingDoc,
              updateTimestamp: new Date(),
              resolvedTimestamp: new Date(),
            },
          }
        : {
            visitUpdate: {
              status: "resolved",
              note: ["ubacareChronicMedSync", "patientManagement"].includes(
                visitType
              )
                ? [...visitNote, note]
                : [...visitNote, newNote],
              isRefer: ["ubacareChronicMedSync", "patientManagement"].includes(
                visitType
              )
                ? false
                : !prescriptionSent,
              currentDoc: null,
              closingDoc,
              updateTimestamp: now,
              resolvedTimestamp: now,
            },
          };
      setIsPatientLoading(true);
      if (isHourly) {
        await updateDocTime(token);
      }
      await updateVisit(_id, data);
      setIsPatientLoading(false);
    } catch (err) {
      console.log(err);
      setIsPatientLoading(false);
    }
  };

  const handlePrescribeClick = async () => {
    if (isHourly) {
      await updateDocTime(token);
    }
    await logEvent(token, "prescribe", {
      userId: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      visitType: visitType,
      company: visit.company,
      masterId: visit.masterId,
    });
    await prescribeClick(
      token,
      user,
      patient,
      visit,
      setIsPatientLoading,
      setDosespotURL,
      setShowDosespot,
      setShowDrugForm,
      setShowNoteBox,
      changeTab
    );
  };

  const handleClose = () => {
    setShowDosespot(false);
    setErrorMessage("");
  };

  const handleCloseWithMed = () => {
    setPrescriptionSent(true);
    setShowDosespot(false);
    setErrorMessage("");
    setShowNoteBox(true);
  };

  const handleCloseWithMedDSV1 = () => {
    setShowDosespot(false);
    setErrorMessage("");
  };

  const handleRefer = async () => {
    if (isHourly) {
      await updateDocTime(token);
    }
    const newText = fullReferText(patient.firstName);
    setMedSelectionCollapsibleState(false);
    setVisitResolutionText(newText);
    setShowNoteBox(true);
    // setHasBeenReferred(true);
  };

  const handleApproveLabOrder = () => {
    setPrescriptionSent(true);
    setShowNoteBox(true);
  };

  const handleShowNoteBox = () => {
    setShowNoteBox(true);
  };

  const handlePrescriptionSent = async () => {
    if (isHourly) {
      await updateDocTime(token);
    }
    const res = await getPrescriptionSent(
      token,
      user,
      patient,
      company,
      updatePatient,
      setErrorMessage,
      handleCloseWithMedDSV1
    );
    return res;
  };

  const handlePrescriptionSentDSFull = async (sentRxList) => {
    const res = await getPrescriptionSentDSFull(
      token,
      patient,
      _id,
      updatePatient,
      setVisitResolutionText,
      handleCloseWithMed,
      sentRxList
    );
    return res;
  };

  const visitTypeCaps = (visitType) => {
    return visitType[0].toUpperCase() + visitType.slice(1);
  };

  const handlePDFChange = (e) => {
    if (e.target.name === "dosage") {
      const directions =
        e.target.value < 10
          ? selectedDrug.directions.low
          : selectedDrug.directions.standard;
      setPdfContent({
        ...pdfContent,
        [e.target.name]: e.target.value,
        directions,
      });
    } else {
      setPdfContent({
        ...pdfContent,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handlePDFChangeBWFL = (e) => {
    if (e.target.name === "dosage") {
      const concentration = selectedDrug.dosages.find(
        (dose) => dose[0] === e.target.value
      )[1];
      const directions = selectedDrug.directions.standard.replace(
        "{strength}",
        e.target.value
      );
      setPdfContent({
        ...pdfContent,
        [e.target.name]: e.target.value,
        medication: `${pdfContent.medication} ${concentration}`,
        dose: e.target.value,
        directions,
      });
    } else {
      setPdfContent({
        ...pdfContent,
        [e.target.name]: e.target.value,
      });
    }
  };

  const createPDF = async () => {
    await createDropboxPDF(token, patient, _id, pdfContent);
  };

  // const handleHallandaleMedSelect = async (medList) => {
  //   const medications = medList.map(m => {
  //     return {
  //       name: m.value
  //     }
  //   });
  //   setSavingState(true);
  //   const error = await sendHallandaleRx(
  //     patient,
  //     _id,
  //     medications,
  //     setPdfEmailError,
  //     setPrescriptionSent,
  //     setVisitResolutionText,
  //     rxTextFromDS,
  //     setShowNoteBox
  //   );
  //   setSavingState(false);
  //   if (error) {
  //     return;
  //   }
  //   setSavedSuccessState(true);
  // }

  const handleSendPdf = async () => {
    switch (company) {
      case "hio":
        await sendS3Pdf(
          token,
          patient,
          _id,
          company,
          rxTextFromPDF,
          setPdfEmailError,
          setPrescriptionSent,
          setVisitResolutionText,
          setShowNoteBox
        );
        break;
      case "betterWeightForLife":
      case "respiri":
        await sendEmailPdfRespiri(
          token,
          patient,
          _id,
          company,
          visitType,
          pdfContent,
          setPdfEmailError,
          setPrescriptionSent,
          setShowNoteBox
        );
        break;
      case "medsForMen":
        await createPDF(token);
        break;
      case "goodyHealth":
        await sendCovidPdf(
          token,
          patient,
          company,
          visit,
          videoResult,
          rxTextFromPDF,
          setPdfEmailError,
          setPrescriptionSent,
          setVisitResolutionText,
          setShowNoteBox,
          updatePatient
        );
        break;
      case "ubacare":
        await sendCanadaPdf(
          token,
          patient,
          _id,
          setPdfEmailError,
          setPrescriptionSent,
          updatePatient,
          setCurrentVisit
        );
        break;
      case "flexHealth":
      case "sikaHealth":
        await sendLetterPdf(
          token,
          patient,
          _id,
          setPdfEmailError,
          setPrescriptionSent,
          setShowNoteBox
        );
        break;
      case "daybreak":
        await sendDaybreakPdf(
          token,
          patient,
          _id,
          rxTextFromPDF,
          setPdfEmailError,
          setPrescriptionSent,
          setVisitResolutionText,
          setShowNoteBox
        );
        break;
      default:
        await sendEmailPdf(
          token,
          patient,
          company,
          visitType,
          rxTextFromPDF,
          setPdfEmailError,
          setPrescriptionSent,
          setVisitResolutionText,
          setShowNoteBox
        );
        break;
    }
  };

  const handleOpenInvalid = () => {
    setIsInvalidOpen(true);
  };

  const handleCloseInvalid = () => {
    setIsInvalidOpen(false);
  };

  const handleInconclusive = async () => {
    await sendInvalidVideo(
      token,
      patient,
      visit,
      updatePatient,
      setCurrentVisit,
      setPdfEmailError,
      setIsInvalidOpen
    );
  };

  const handleCovidSelect = (e) => {
    setVideoResult(e.target.value);
  };

  const handleDrugSelection = (e) => {
    e.preventDefault();
    setSelectedDrug(medsForMen[e.target.value]);
    setPdfContent({
      [e.target.name]: e.target.value,
    });
  };

  const handleDrugSelectionBWFL = (e) => {
    setSelectedDrug(betterWeightForLife[e.target.value]);
    setPdfContent({
      [e.target.name]: e.target.value,
    });
  };

  const hideDrugForm = () => {
    setPdfContent({});
    setSelectedDrug({});
    setShowDrugForm(false);
  };

  const defaultOption = (field) => {
    return (
      <option value="None" disabled>
        Select {field}
      </option>
    );
  };

  const cancelAdminResolve = () => {
    setIsAdminResolve(false);
    setShowNoteBox(false);
  };

  const openHioResolve = () => {
    setHioResolve(true);
  };

  const handleHioOutcome = (e) => {
    handleHioOutcomeChange(e.target.value);
  };

  const handleHioNote = (e) => {
    handleHioNoteChange(e.target.value);
  };

  const switchPref = () => {
    let prefs;
    switch (true) {
      case customPrefCompanies.includes(company):
        prefs = formattedPatientPref;
        break;
      case company === "myDrHank":
      case company === "247Health":
        const formObj = visit.formObj;
        const medKey = Object.keys(formObj).find((key) =>
          /Patient Preference/i.test(formObj[key])
        );
        const medAnswer = medKey ? formObj[`A${medKey.slice(1)}`] : "";
        prefs = <span key={formObj[medKey]}> {medAnswer} </span>;
        break;
      case patientPreferenceCompanies.includes(company) ||
        /^bask/.test(company):
        prefs = patientPreferences;
        break;
      default:
        return null;
    }
    return prefs;
  };

  const switchResolveSection = () => {
    switch (true) {
      case // labCompanies.includes(company) &&
      !!user.npi &&
        Object.keys(visit.formObj).includes("testToTreat") &&
        !testToTreat:
        return (
          <div className="resolveButtonsContainer">
            <button
              onClick={handleApproveLabOrder}
              className="visitButton resolveButton"
            >
              Complete Visit
            </button>
          </div>
        );
      case company === "hio" && !!user.npi:
        if (visitType === "acr" || visitType === "postResultAcr") {
          return (
            <div className="resolveButtonsContainer">
              {hioResolve ? null : (
                <button
                  onClick={openHioResolve}
                  className="visitButton resolveButton"
                >
                  Open Assessment
                </button>
              )}
            </div>
          );
        } else {
          return (
            <>
              <div className="resolveButtonsContainer">
                <button
                  onClick={handleSendPdf}
                  disabled={prescriptionSent}
                  className="visitButton resolveButton"
                >
                  Send PDF rx
                </button>
                <button
                  onClick={handleRefer}
                  disabled={prescriptionSent}
                  className="visitButton resolveButton"
                >
                  Refer
                </button>
              </div>
              {pdfEmailError && <div>{pdfEmailError}</div>}
            </>
          );
        }
      case company === "goodyHealth" && !!user.npi:
        return (
          <>
            <div className="resolveButtonsContainer">
              <select defaultValue="" onChange={handleCovidSelect}>
                <option value="" disabled>
                  Select Result
                </option>
                <option value="Positive">Positive</option>
                <option value="Negative">Negative</option>
              </select>
              <button
                onClick={handleSendPdf}
                className="visitButton resolveButton"
                disabled={!videoResult || prescriptionSent}
              >
                Send PDF certification
              </button>
              <button
                onClick={handleOpenInvalid}
                className="visitButton resolveButton"
              >
                Inconclusive Test
              </button>
            </div>
            {pdfEmailError && <div>{pdfEmailError}</div>}
          </>
        );
      case showDrugForm && !!user.npi:
        return (
          <div className="resolveButtonsContainer">
            <button onClick={hideDrugForm} className="formButton">
              Cancel
            </button>
            <button onClick={handleSendPdf} className="formButton">
              Send rx
            </button>
          </div>
        );
      case company === "ubacare" && /canadaRx/.test(visitType) && !!user.npi:
      case company === "flexHealth" && !!user.npi:
      case company === "sikaHealth" && !!user.npi:
      case company === "powerplate" && !!user.npi:
      case company === "neurolief" && !!user.npi:
      case company === "daybreak" && !!user.npi:
      case company === "respiri" && !!user.npi:
        return (
          <>
            <div className="resolveButtonsContainer">
              <button
                onClick={handleSendPdf}
                className="visitButton resolveButton"
              >
                Send PDF rx
              </button>
              <button
                onClick={handleRefer}
                disabled={prescriptionSent}
                className="visitButton resolveButton"
              >
                Refer
              </button>
            </div>
            {pdfEmailError && <div>{pdfEmailError}</div>}
          </>
        );
      case visitType === "ubacareChronicMedSync":
        return (
          <div className="resolveButtonsContainer">
            <button
              onClick={handleShowNoteBox}
              className="visitButton resolveButton"
            >
              Resolve Visit
            </button>
            <button
              onClick={handleRefer}
              disabled={prescriptionSent}
              className="visitButton resolveButton"
            >
              Refer
            </button>
          </div>
        );
      case switchFullDS(company, visitType):
        return (
          <>
            {admin && !!user.npi && (
              <div className="resolveButtonsContainer">
                <button
                  onClick={handlePrescribeClick}
                  className="visitButton resolveButton withMedicineSelection"
                >
                  Dosespot Prescribe
                </button>
              </div>
            )}
            <div style={{ padding: "0 5px" }}>
              <CollapsibleContent
                title={"Medicine Selection"}
                id={"dosespot-medicine-selection"}
                openContentState={medSelectionCollapsibleState}
                body={
                  <PrescriptionSelect
                    visitType={visitType}
                    prepopulate={switchFullDS(company, visitType)}
                    patientPreference={patientPreference}
                    patientId={patient._id}
                    weight={patient.weight}
                    email={patient.email}
                    visitId={visit._id}
                    visit={visit}
                    user={user}
                    setMedSelectionCollapsibleState={
                      setMedSelectionCollapsibleState
                    }
                    setShowNoteBox={setShowNoteBox}
                    handlePrescriptionSentDSFull={handlePrescriptionSentDSFull}
                    handleRefer={handleRefer}
                    prescriptionSent={prescriptionSent}
                    pharmacyId={pharmacyId}
                    setIsPatientLoading={setIsPatientLoading}
                    isHourly={isHourly}
                    updateVisitPharmacy={updateVisitPharmacy}
                    // hasBeenReferred={hasBeenReferred}
                    pdfEmailError={pdfEmailError}
                    sendEmailPdf={(data) =>
                      sendEmailPdfVar(
                        token,
                        patient,
                        _id,
                        company,
                        visitType,
                        data,
                        rxTextFromPDF,
                        setPdfEmailError,
                        setPrescriptionSent,
                        setVisitResolutionText,
                        setShowNoteBox,
                        setShowDrugForm
                      )
                    }
                    sendFaxPdf={(data) =>
                      sendJasePdf(
                        token,
                        patient,
                        visitType,
                        _id,
                        setPdfEmailError,
                        setPrescriptionSent,
                        setVisitResolutionText,
                        rxTextFromPDF,
                        setShowNoteBox,
                        data
                      )
                    }
                    sendHallandalePdf={(data) =>
                      sendHallandalePdf(
                        token,
                        patient,
                        _id,
                        setPdfEmailError,
                        setPrescriptionSent,
                        setVisitResolutionText,
                        rxTextFromPDF,
                        setShowNoteBox,
                        data
                      )
                    }
                    sendUpliftPdf={(data) =>
                      sendUpliftPdf(
                        token,
                        patient,
                        _id,
                        setPdfEmailError,
                        setPrescriptionSent,
                        setVisitResolutionText,
                        rxTextFromPDF,
                        setShowNoteBox,
                        data
                      )
                    }
                  />
                }
                contentStyle={{
                  height: "100%",
                  padding: "0 2px",
                }}
              />
            </div>
          </>
        );
      case !isAdminResolve && !!user.npi && !showDrugForm:
        return (
          <div className="resolveButtonsContainer">
            <button
              onClick={handlePrescribeClick}
              className="visitButton resolveButton"
            >
              Prescribe
            </button>
            <button
              onClick={handleRefer}
              disabled={prescriptionSent}
              className="visitButton resolveButton"
            >
              Refer
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const switchNoteSection = () => {
    switch (true) {
      case isAdminResolve:
        return (
          <>
            <button
              onClick={handleSaveNoteAndResolve}
              disabled={!canSaveNote}
              className="visitButton noteButton"
            >
              Save Note and Resolve
            </button>
            <button
              onClick={cancelAdminResolve}
              className="visitButton noteButton"
            >
              Cancel Admin Resolve
            </button>
          </>
        );
      case /canadaRx/.test(visitType):
      case company === "flexHealth":
      case visitType === "ubacareChronicMedSync":
      case visitType === "apneaVideo":
      case company === "respiri":
      case company === "sikaHealth":
        return (
          <button
            onClick={handleSaveNoteAndResolve}
            disabled={!canSaveNote}
            className="visitButton noteButton"
          >
            Save Note and Resolve
          </button>
        );
      default:
        return (
          <button
            onClick={handleSaveNote}
            id="prescribeVisit"
            disabled={!canSaveNote || isPatientLoading}
            className="visitButton noteButton"
          >
            Save Note
          </button>
        );
    }
  };

  const panOnMouseMove = (e) => {
    let boundingRect = e.currentTarget.getBoundingClientRect();
    e.currentTarget.style["transform-origin"] = `${
      ((e.pageX - boundingRect.left) / boundingRect.width) * 100
    }%
    ${((e.pageY - boundingRect.top) / boundingRect.height) * 100}%`;
  };

  const hioOutcomeArray =
    visitType === "acr" ? ["approved", "denied", "opt-out"] : ["resolved"];

  const photosArray = idPhotos.map((photo, idx) => {
    return (
      <div key={photo.name} className="photoCard">
        <img
          src={photo.src}
          className="imageSizingResolve"
          alt={photo.name}
          onMouseMove={panOnMouseMove}
        />
        <p>{idx}</p>
      </div>
    );
  });

  const handlePhotoDropdown = () => {
    setCurrentDropdown(() => (currentDropdown === "photo" ? "" : "photo"));
  };

  const handleChatDropdown = () => {
    setCurrentDropdown(() => (currentDropdown === "chat" ? "" : "chat"));
  };

  const photoClass = currentDropdown === "photo" ? "dsDropdown" : "hidden";
  const chatClass = currentDropdown === "chat" ? "dsDropdown" : "hidden";
  const photoActionClass =
    currentDropdown === "photo" ? "dsDropdownAction open" : "dsDropdownAction";
  const chatActionClass =
    currentDropdown === "chat" ? "dsDropdownAction open" : "dsDropdownAction";

  useEffect(() => {
    if (!note.length) {
      setCanSaveNote(false);
    } else {
      setCanSaveNote(true);
    }
  }, [note]);

  useEffect(() => {
    if (visit.rxHistory?.length) {
      if (!visit.note.length) {
        setShowNoteBox(true);
      } else {
        setShowNoteBox(false);
      }
      setMedSelectionCollapsibleState(false);
    } else {
      setShowNoteBox(false);
      setMedSelectionCollapsibleState(true);
    }
    setHioResolve(false);
  }, [visit]);

  useEffect(() => {
    Object.keys(selectedDrug).length &&
      setPdfContent({
        ...pdfContent,
        dosage: "None",
      });
  }, [selectedDrug]);

  useEffect(() => {
    if (isAdminResolve) {
      setShowNoteBox(true);
      setMedSelectionCollapsibleState(false);
    }
  }, [isAdminResolve]);

  return (
    <>
      <div className="resolveAndNotes">
        {showDrugForm &&
          (visitType === "EDbrand" ? (
            <div className="rxFormStyle">
              <div className="formRow">
                <div>
                  <div className="formField">Medication</div>
                  <select
                    name="medication"
                    onChange={handleDrugSelection}
                    defaultValue="None"
                  >
                    {defaultOption("medication")}
                    {Object.keys(medsForMen).map((drug, idx) => {
                      return (
                        <option value={drug} key={idx}>
                          {drug}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {pdfContent.medication && (
                <>
                  <div className="formRow">
                    <div>
                      <div className="formField">Dosage</div>
                      <select
                        name="dosage"
                        onChange={handlePDFChange}
                        value={pdfContent.dosage}
                      >
                        {defaultOption("dosage")}
                        {Object.keys(selectedDrug).length &&
                          selectedDrug.dosages.map((dose, idx) => {
                            return (
                              <option value={dose} key={idx}>
                                {dose}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {pdfContent.dosage !== "None" && (
                    <>
                      <div className="formRow">
                        <div>
                          <div className="formField">Quantity</div>
                          <select
                            name="quantity"
                            onChange={handlePDFChange}
                            defaultValue="None"
                          >
                            {defaultOption("quantity")}
                            {Object.keys(selectedDrug).length &&
                              selectedDrug.quantity.map((quant, idx) => {
                                return (
                                  <option value={quant} key={idx}>
                                    {quant}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      {pdfContent.quantity && (
                        <>
                          <div className="formRow">
                            <div>
                              <div className="formField">Refills</div>
                              <select
                                name="refills"
                                onChange={handlePDFChange}
                                defaultValue="None"
                              >
                                {defaultOption("refill #")}
                                {Object.keys(selectedDrug).length &&
                                  selectedDrug.refills.map((refill, idx) => {
                                    return (
                                      <option value={refill} key={idx}>
                                        {refill}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="formRow">
                            <div>
                              <div className="formField">
                                Patient Directions
                              </div>
                              <textarea
                                name="directions"
                                onChange={handlePDFChange}
                                value={pdfContent.directions}
                                rows={5}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="rxFormStyle">
              <div className="formRow">
                <div>
                  <div className="formField">Medication</div>
                  <select
                    name="medication"
                    onChange={handleDrugSelectionBWFL}
                    defaultValue="None"
                  >
                    {defaultOption("medication")}
                    {Object.keys(betterWeightForLife).map((drug, idx) => {
                      return (
                        <option value={drug} key={idx}>
                          {drug}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {pdfContent.medication && (
                <>
                  <div className="formRow">
                    <div>
                      <div className="formField">Dosage</div>
                      <select
                        name="dosage"
                        onChange={handlePDFChangeBWFL}
                        value={pdfContent.dosage}
                      >
                        {defaultOption("dosage")}
                        {Object.keys(selectedDrug).length &&
                          selectedDrug.dosages.map((dose, idx) => {
                            return (
                              <option value={dose[0]} key={idx}>
                                {dose[0]}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {pdfContent.dosage !== "None" && (
                    <>
                      <div className="formRow">
                        <div>
                          <div className="formField">Quantity</div>
                          <select
                            name="quantity"
                            onChange={handlePDFChangeBWFL}
                            defaultValue="None"
                          >
                            {defaultOption("quantity")}
                            {Object.keys(selectedDrug).length &&
                              selectedDrug.quantity.map((quant, idx) => {
                                return (
                                  <option value={quant[0]} key={idx}>
                                    {quant[0]}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      {pdfContent.quantity && (
                        <>
                          <div className="formRow">
                            <div>
                              <div className="formField">Refills</div>
                              <select
                                name="refills"
                                onChange={handlePDFChangeBWFL}
                                defaultValue="None"
                              >
                                {defaultOption("refill #")}
                                {Object.keys(selectedDrug).length &&
                                  selectedDrug.refills.map((refill, idx) => {
                                    return (
                                      <option value={refill} key={idx}>
                                        {refill}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="formRow">
                            <div>
                              <div className="formField">
                                Patient Directions
                              </div>
                              <textarea
                                name="directions"
                                onChange={handlePDFChangeBWFL}
                                value={pdfContent.directions}
                                rows={5}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          ))}
        {hioResolve && (
          <div className="rxFormStyle">
            <div className="formRow">
              <div>
                <div className="formField">Assessment</div>
                <select
                  name="outcome"
                  onChange={handleHioOutcome}
                  defaultValue="None"
                >
                  {defaultOption("Assessment")}
                  {hioOutcomeArray.map((outcome) => {
                    return (
                      <option value={outcome} key={outcome}>
                        {outcome}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="formRow">
              <div>
                <div className="formField">Note</div>
                <textarea
                  onChange={handleHioNote}
                  placeholder="Enter notes here"
                  rows={10}
                />
              </div>
            </div>
            <button
              onClick={handleResolveHio}
              className="visitButton noteButton"
              disabled={!canSaveNote || !hioOutcome.outcome}
            >
              Save note and resolve
            </button>
          </div>
        )}
        {switchVideoDone() && switchResolveSection()}
        {showNoteBox && (
          <div className="notesContainer">
            <div>Assessment & Plan (Internal Note)</div>
            <textarea
              name="notes"
              onChange={handleNotes}
              placeholder="Enter notes here"
              className="noteBox"
              value={note}
            />
            {switchNoteSection()}
          </div>
        )}
      </div>

      <Modal
        show={showDosespot}
        onHide={handleClose}
        dialogClassName="dsModal"
        backdrop="static"
      >
        <div className="modalHeader">
          <div className="modalTitle">
            <span>
              {company} {visitTypeCaps(visitType)}
            </span>
            <button onClick={handleClose} className="modalCloseButton">
              <img src={xIcon} className="xIcon" alt="close" />
            </button>
          </div>
        </div>
        <div className="dsModalBody">
          <div className="dsModalSidebar">
            <div className="resolveError">{errorMessage}</div>
            <p>Patient Preference:</p>
            {company === "jase" && (
              <p className="boldred">Please scroll down</p>
            )}
            <div className="scrollablePref">
              <table>
                <tbody>{switchPref()}</tbody>
              </table>
            </div>
            {company === "jase" && (
              <>
                <div onClick={handlePhotoDropdown} className="dsDropdownButton">
                  Photos
                  <span className={photoActionClass}>+</span>
                </div>
                <div onClick={handleChatDropdown} className="dsDropdownButton">
                  Internal Chat
                  <span className={chatActionClass}>+</span>
                </div>
                <div className={photoClass}>{photosArray}</div>
                <div className={chatClass}>{visitInternalChat}</div>
              </>
            )}
          </div>
          <IFrame
            id="ifrm"
            url={dsUrl}
            width="100%"
            height="100%"
            display="initial"
            position="relative"
            overflow="hidden"
          />
        </div>
        <div className="modalFooter">
          <button onClick={handleClose} className="visitButton">
            Close
          </button>
          <button onClick={handlePrescriptionSent} className="visitButton">
            Done
          </button>
        </div>
      </Modal>

      <Modal
        show={isInvalidOpen}
        onHide={handleCloseInvalid}
        dialogClassName="dsModal"
        backdrop="static"
      >
        <div className="modalHeader">
          <div className="modalTitle">
            <span>
              {company} {visitTypeCaps(visitType)}
            </span>
            <button onClick={handleCloseInvalid} className="modalCloseButton">
              <img src={xIcon} className="xIcon" alt="close" />
            </button>
          </div>
        </div>
        <div className="modalBody">
          Are you sure you want to mark this video as invalid and send a message
          to the patient asking them to resubmit their COVID test recording?
        </div>
        <div className="modalFooter">
          <button onClick={handleCloseInvalid} className="visitButton">
            Close
          </button>
          <button onClick={handleInconclusive} className="visitButton">
            Confirm video is invalid
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ResolveContainer;
