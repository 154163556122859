const isBaskCompany = (company) => {
  return /^bask/.test(company);
};

const switchMatch = (company, pref, fav) => {
  switch (true) {
    case isBaskCompany(company):
      return pref.packageNDC;
    default:
      return (
        pref.medId?.toLowerCase().replace(/\s/g, "") ||
        pref.name.toLowerCase().replace(/\s/g, "")
      );
  }
};

const switchFavField = (fav, company) => {
  switch (true) {
    case isBaskCompany(company):
      return fav.baskName;
    default:
      return fav.clientName.toLowerCase().replace(/\s/g, "");
  }
};

const switchFavFieldAlternate = (fav, company) => {
  switch (true) {
    case isBaskCompany(company):
      return fav.baskName;
    default:
      return fav.clientName;
  }
};

export const pharmacyNoteDict = {
  /*
    hallandale: 243478
    red rock: 251162
    red rock alt: 250720
    red rock slc: 75990
    curexa: 29992
    honeybee: 254295
    anazao: 240931
    nova: 269229
    gogomeds: 245312
    precision: 265585
    rxCompoundStore: 255863
    revive: 250664
    shields: 254283
    boothwyn: 41717
    midtown: 45162
    mfv: 271235
    strive: 255546
    southend: 239252
    wells: 73382
    belmar: 72323
    pharmalabs: 73586
    pharmacyHub: 271328
    promise: 8453
    nationwide: 252225
    foothills: 1978
  */
  mitochondria: {
    243478:
      "PracticeID: mitochondria_health  Bill to Clinic, Ship to Patient; ",
    269229: "Client: Nextmed; ",
  },
  poshdoc: {
    243478:
      "PracticeID: mitochondria_health  Bill to Clinic, Ship to Patient; ",
    269229: "Client: Poshdoc; ",
  },
  motivated: {
    254295: "motivated; ",
    29992: "motivated; ",
  },
  orderlyMeds: {
    243478:
      "PracticeID: ORDERLY MEDS (ID: 1194376)  Bill to Clinic, Ship to Patient; ",
    240931: "Account S01533 (Orderlymeds)  Bill to Clinic, Ship to Patient; ",
    251162: "Pharmacy-Bill to OrderlyMeds, Ship to Patient; ",
    250720: "Pharmacy-Bill to OrderlyMeds, Ship to Patient; ",
    75990: "Pharmacy-Bill to OrderlyMeds, Ship to Patient; ",
    255546: "Orderlymeds; ",
    239252: "Beluga Health; ",
    271328: "Orderlymeds; ",
    252225: "Orderlymeds; ",
  },
  katherineCary: {
    243478: "PracticeID: KatherineCary;  Bill to Clinic, Ship to Patient; ",
    271328: "Katherine Cary, LLC; ",
  },
  venus: {
    243478:
      "PracticeID: Medical Brands (ID: 1194590);  Bill to Clinic, Ship to Patient; ",
    271328: "Medical Brands",
  },
  aspectHealth: {
    265585: "Aspect; ",
  },
  urway: {
    271235: "Urway Health; ",
  },
  architekTangl: {
    74186: "Tangl",
  },
  jackHealth: {
    255863: "Jack Jill Health; ",
    265585: "Jack Jill Health; ",
    255546: "Jack Jill Health; ",
    239252: "Jack Jill Health; ",
    73382: "Jack Jill Health; ",
    72323: "Jack Jill Health; ",
    29992: "Jackhealth; ",
  },
  newHopeWellness: {
    73586: "Bill Newhope Wellness; ",
  },
  kmish: {
    251162: "Bill to Kmish, ship to patient; ",
    250720: "Bill to Kmish, ship to patient; ",
    75990: "Bill to Kmish, ship to patient; ",
  },
  fitScripts: {
    73382: "Bill to Fitscripts; ",
    8453: "Bill to Fitscripts; ",
    271328: "Bill to Fitscripts; ",
  },
  zepmeds: {
    243478: "Originmeds; ",
    251162: "bill to ORIGINMEDS, ship to patient; diagnosis: E66.9; ",
    250720: "bill to ORIGINMEDS, ship to patient; diagnosis: E66.9; ",
    75990: "bill to ORIGINMEDS, ship to patient; diagnosis: E66.9; ",
    271328: "Originmeds; ",
  },
  gulaworld: {
    41717: "Bill Gulaworld; ",
  },
  oatMeds: {
    251162: "Bill to Oat Meds / Ship to Patient; ",
    250720: "Bill to Oat Meds / Ship to Patient; ",
    75990: "Bill to Oat Meds / Ship to Patient; ",
    243478: "Bill Oat Meds LLC; ",
  },
  drDerm: {
    251162: "DoctorDerm (Aucuria Inc.) will pay; ",
    250720: "DoctorDerm (Aucuria Inc.) will pay; ",
    75990: "DoctorDerm (Aucuria Inc.) will pay; ",
  },
  totalMaleRx: {
    29992: "Total Male Rx; ",
  },
  foundationVeri: {
    265585: "Foundation Health",
  },
  foundationDocus: {
    265585: "Foundation Health",
  },
  foundationGobyMeds: {
    265585: "Foundation Health",
  },
  mailMyMeds: {
    243478:
      "Clinic Name: Mail My Meds; ship to: Patient address; Bill to: Mail My Meds; MasterId: {masterId}; ",
  },
  readyRx: {
    265585: "ReadyRx Patient",
  },
  doctorApprovedMeds: {
    8453: "Doctor Approved Meds",
    1978: "Doctor Approved Meds",
  },

  baskAmbleHealth: {
    251162: "baskAmbleHealth; ",
    250720: "baskAmbleHealth; ",
    75990: "baskAmbleHealth; ",
    250664: "baskAmbleHealth; ",
    255863: "baskAmbleHealth; ",
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskMyaMeds: {
    251162: "baskMyaMeds; ",
    250720: "baskMyaMeds; ",
    75990: "baskMyaMeds; ",
  },
  baskDudeMeds: {
    29992: "baskDudeMeds; ",
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskSeiseidouAmericaInc: {
    254295: "baskSeiseidouAmericaInc; ",
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskMedicology: {
    251162: "baskMedicology; ",
    250720: "baskMedicology; ",
    75990: "baskMedicology; ",
    255863: "baskMedicology; ",
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskMedccm: {
    251162: "baskMedccm; ",
    250720: "baskMedccm; ",
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskRivas: {
    254283: "baskSlenDoc; ",
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskEdenHealthInternational: {
    265585: "BaskEden; ",
    29992: "baskEden; ",
    41717: "Bill to Eden; Patient email: {patientEmail}; NDC: {NDC}; ",
    45162: "Bill to Eden; ",
  },
  baskLaylaHealthcare: {
    243478: "PracticeID: bask;  Bill to Clinic, Ship to Patient; ",
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskGoodLifeMeds: {
    269229: "Client: Goodlife; ",
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskHevaHealth: {
    255546: "Bill Heva Health, Ship to Patient; ",
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskEffecty: {
    243478:
      "PracticeID: baskEffecty;  Bill to Clinic, Ship to Patient; Include Effecty product code 8064145; ",
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  bask: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  bask986HealthLlc: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskArk: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskBespokeConcepts: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskCareValidate: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskCreo: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskDaywel: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskEasyRx: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskEnovOne: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskEverythingMenLlc: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskGetThin: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskGettrim: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskGileMarketingSolution: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskHarper: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskHealthRenew: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskHealthyId: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskIvyRx: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskJojoLuluLlc: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskJulyMD: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskLockLabLlc: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskMangoTelehealthInc: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskMojoonInc: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskMomentHealth: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskMwlcoaLlc: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskNexa: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskNuyuHealthWellnessInc: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskOpalRx: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskPhill: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskPillCare: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskPillowTalkDerm: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskPlatinumMdLlc: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskRemiGroup: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskRemotePharmacy: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskRxDirectOnlineLlc: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskRxSlimCare: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskShoww: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskSimpleMd: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskSkinnyTheory: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskSlenderMe: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskTowerMeds: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskTrimi: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskVeloxRx: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskVital: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskWellmashProductsInc: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskYou4You: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskYoungerYou: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
  baskYourxHealth: {
    41717: "Bill to Bask; Patient email: {patientEmail}; NDC: {NDC}; ",
  },
};

const pharmacyNoteDiagnosis = {
  weightloss: "Obesity, E66.9",
  weightlossfollowup: "Obesity, E66.9",
  weightmaintenance: "Obesity, E66.9",
  ED: "Erectile Dysfunction",
  acne: "Acne",
};

export const switchPharmNotes = (
  masterId,
  company,
  visitType,
  rxHistory,
  diagnosis,
  pharmacyId,
  email,
  baskName,
  rxNotes
) => {
  const dupText =
    "This prescription replaces the previous prescription for this patient. ";
  let fullNotes = "";
  if (rxHistory?.length > 0) {
    fullNotes = fullNotes + dupText;
  }
  if (isBaskCompany(company)) {
    if (pharmacyNoteDict[company]) {
      if (pharmacyNoteDict[company][pharmacyId]) {
        fullNotes =
          fullNotes +
          pharmacyNoteDict[company][pharmacyId]
            .replace("{patientEmail}", email)
            .replace("{NDC}", baskName);
      } else fullNotes = fullNotes + "bask";
    } else fullNotes = fullNotes + "bask";
  } else if (company === "mitochondria" || company === "poshdoc") {
    if (pharmacyNoteDict[company]) {
      if (pharmacyNoteDict[company][pharmacyId]) {
        fullNotes = fullNotes + `${pharmacyNoteDict[company][pharmacyId]}`;
      }
    }
  } else if (company === "urway") {
    fullNotes = `MasterId: ${masterId} ` + fullNotes;
    if (pharmacyNoteDict[company]) {
      if (pharmacyNoteDict[company][pharmacyId]) {
        fullNotes = fullNotes + pharmacyNoteDict[company][pharmacyId];
      }
    }
  } else if (company === "ubacare") {
    fullNotes = `Diagnosis: ${diagnosis} ` + fullNotes;
    if (pharmacyNoteDict[company]) {
      if (pharmacyNoteDict[company][pharmacyId]) {
        fullNotes = fullNotes + pharmacyNoteDict[company][pharmacyId];
      }
    }
  } else if (company === "jase" && visitType.includes("jaseCase")) {
    fullNotes = rxNotes + "; " + fullNotes;
    if (pharmacyNoteDict[company]) {
      if (pharmacyNoteDict[company][pharmacyId]) {
        fullNotes = fullNotes + pharmacyNoteDict[company][pharmacyId];
      }
    }
  } else if (company === "mailMyMeds") {
    if (pharmacyNoteDict[company]) {
      if (pharmacyNoteDict[company][pharmacyId]) {
        fullNotes =
          fullNotes +
          pharmacyNoteDict[company][pharmacyId].replace("{masterId}", masterId);
      }
    }
  } else {
    if (pharmacyNoteDict[company]) {
      if (pharmacyNoteDict[company][pharmacyId]) {
        fullNotes = fullNotes + pharmacyNoteDict[company][pharmacyId];
      }
    }
  }
  if (Object.keys(pharmacyNoteDiagnosis).includes(visitType)) {
    fullNotes = fullNotes + ` Diagnosis: ${pharmacyNoteDiagnosis[visitType]}; `;
  }
  return fullNotes;
};

const variableFavs = [
  // syringes
  "jVgR5tAzuXxhqxc5VVOYahvy80D4KfXD", // client 'Sure Comfort Insulin Syringe'
  "ca32cf0b-c966-4e5f-8b65-3789040a3491", // bask 'Sure Comfort Insulin Syringe'
  "f666fb0d-0c70-41b3-855d-cb554e9190c3", // bask 'Ulticare 30G 5/16" 0.5mL Syringes'
  "vK9a5QGcVcaXQc6N9Qsl8cFl9wJTbwiY", // client 'GLP-1 Injection Kit'
  "7963303", // bask 'GLP-1 Injection Kit'
  // sildenafil
  "nJG541l2TFjY7F1kiXz85GRb74Xjo64C", // client 'Sildenafil 20mg'
  "0068-4210", // bask 'Sildenafil 20mg'
  "pUnRU0n1U16Bg6IrxV9OnKu76f3GQeHS", // client 'Sildenafil 25mg'
  "BASK-BIKP-HUB-376", // bask Sildenafil 25mg
  "7hTtwvBEXAsl59aY0pRfdEID589eCQpu", // client 'Sildenafil 50mg'
  "27241006803", // bask 'Sildenafil 50mg'
  "AYZ1O8CKDvxRAa6Sh7vBIJYZtYizMATJ", // client 'Sildenafil 100mg'
  "31722071101", // bask Sildenafil 100mg
  // tadalafil
  "zChbPSc8V4E1z08yUtpQBYe1txxZz6Rg", // client 'Tadalafil 10mg'
  "6729037116", // bask 'Tadalafil 10mg'
  "Z7N4qW9zMJ9KL4DOnXs2FkzwHIriZGZa", // client 'Tadalafil 5mg'
  "16729037010", // bask 'Tadalafil 5mg'
  "1eKRguwOIgC8h6FRNLWCbwjgfZjBPiFJ", // client 'Tadalafil 20mg'
  "16729037210", // bask 'Tadalafil 20mg'
  "JfrAleUXq9a4CumeMRscYGGKyLDLOQLx", // client 'Tadalafil 2.5mg'
  "BASK-TADALAFIL-25MG", // bask 'Tadalafil 2.5mg'
  // cialis
  "IlAQT6HIUTq6fVD7OvyQRNNXBIcaVUx2", // client 'Cialis 5mg'
  "CMdQFwtjFYSipMAdXxi2IbRkuqGNOu86", // client 'Cialis 10mg'
  "ws19RbruJCJ8t7HJIlwc0bZL1CnRA2oG", // client 'Cialis 20mg'
  "PHCeKTElmrgLFAM6yJrqXOSVnaRgjqde", // client 'Cialis 2.5mg'
  "BASK-BIKP-HUB-341", // bask Cialis 5mg
  "BASK-BIKP-HUB-343", // bask Cialis 10mg
  "BASK-BIKP-HUB-348", // bask Cialis 20mg
  // viagra
  "xwHOedzuw2QnQBah6xRuvS2TnKVyFz4j", // client 'Viagra 25mg'
  "ifpnFfaZi48xloHU98aqd7Xo4tOa1daQ", // client 'Viagra 50mg'
  "8PQ6UbprecHsr5U4E1nCjXgjEjbSDAy3", // client 'Viagra 100mg'
  "BASK-BIKP-HUB-363", // bask Viagra 25mg
  "BASK-BIKP-HUB-367", // bask Viagra 50mg
  "BASK-BIKP-HUB-372", // bask Viagra 100mg
  // troches
  "21722071001", // bask 'Tadalafil Troche 22mg'
  "21722071002", // bask 'Tadalafil+Sildenafil Troche 12mg/60mg'
  "21722071003", // bask 'Tadalafil+Sildenafil Troche 22mg/110mg'
  // finasteride
  "YbS4W45zs7EImDPJ4Lbs5DVWhUqMxxX5", // client 'finasteride 1mg'
  "67877045590", // bask 'finasteride 1mg'
  // vardenafil
  "JDlKcLLgE8IEBDL3fI5YQlT02Xt75RGw", // client 'Vardenafil 20mg'
  "vardenafil-20-hub", // bask Vardenafil 20mg
  // weightlossupper meds
  "EZygESBXb2B4IUtZ5nZsCrQPTahmkt3b", // client Ozempic 2mg
  "00169477212-5", // bask Ozempic 2mg
  "DzvnUxR8LalsvMsd9YuMFkP0I58C0Aog", // client Wegovy 2.4mg
  "169452414", // bask Wegovy 2.4mg
  "i2v09u191e6492O4aVg0dmSaiL0Ghmd9", // client Mounjaro 12.5mg
  "2146080", // bask Mounjaro 12.5mg
  "OovZC6RIApK3YUojXKjfnMmun9Bhdwx1", // client Mounjaro 15mg
  "2145780", // bask Mounjaro 15mg
  "blZ7IsnhEKGAW23Gqy0HyETXsd090Asn", // client Zepbound 12.5mg
  "2246080", // bask Zepbound 12.5mg
  "1of1tGyZEShjGcHgJct0pQBjyd5KagKY", // client Zepbound 15mg
  "2245780", //  bask Zepbound 15mg
  "dk2HXJ6McForahSbjhMtjfCDCaWBNlzT", // client Rosuvastatin 5mg
  "luf61tqrqFnHWiEB1wHAMhGGRhR3fGwF", // client Rosuvastatin 10mg
  "KD7vcarX0L3Dhz62oHY7sNYrCEtRi2o0", // client Rosuvastatin 20mg
  "YIVwFDF14sgFfsqjM0OyLf3vjTkQ0bbJ", // client Atorvastatin 10mg
  "ZEUcwkCqYpLDGPubDDgYPRMmYj4MWcsm", // client Atorvastatin 20mg
  "PRzQwUMDQ4PvNBGVVJyedIKue9lAMUi1", // client Atorvastatin 40mg
  "jABTMAkC9ewEHF9JSk7cmJhM1IVISbQ9", // client Pravastatin 10mg
  "pfqakkdExvAkmIliur2scxg1eZTK0Fs9", // client Pravastatin 20mg
  "tsKOV55OKo4O86CVoPH4lJsMLQowO09h", // client Pravastatin 40mg
  "8rxW1qeKS0XzYjgIqGVQwSx6Rc6CAlT3", // client Ezetimibe 10mg
  "MET-BASK-HUB", // bask Metformin ER 500mg
  "HEVA-BLOOM", // bask Heva Bloom
  "HEVA-RISE", // bask Heva Rise
  "49884025601", // bask Minoxidil 2.5
  "16729021616", // bask Sertraline 50mg
  "50383091203", // bask Latisse 3ml
];

const variableFavCompanies = [
  "motivated",
  "mitochondria",
  "poshdoc",
  "venus",
  "siphox",
  "jackHealth",
  "katherineCary",
  "totalMaleRx",
  "genRx",
];

const weightlossNamedFieldsCompanies = [
  "baskAmbleHealth",
  "baskEdenHealthInternational",
  "baskTrimi",
  "zepmeds",
];

export const getSelectableMedications = (
  {
    masterId,
    company,
    visitType,
    rxHistory,
    formObj: {
      diagnosis,
      currentWeightloss,
      weightlossPreference,
      currentDose,
      rxNotes,
    },
  },
  weight,
  email,
  pharmacyId,
  patientPreference,
  favorites = []
) => {
  // const isBaskCompany = /^bask/.test(company);
  const simplePrefs =
    patientPreference && Array.isArray(patientPreference)
      ? patientPreference.map((pref) => {
          return {
            name: switchMatch(company, pref),
            quantity: parseFloat(pref.quantity),
            refills: parseInt(pref.refills),
            daysSupply: parseInt(pref.daysSupply),
            frequency: pref.frequency,
            pillsPerDose: pref.pillsPerDose,
          };
        })
      : [];

  const selectableMedications = favorites.map((fav) => {
    let isPatientPreference = false;
    const matchedPref = simplePrefs.find(
      (pref) => pref.name === switchFavField(fav, company)
    );

    const switchQuantity = (company, visitType, fav, isPref) => {
      if (
        (isBaskCompany(company) || variableFavCompanies.includes(company)) &&
        isPref &&
        (variableFavs.includes(switchFavFieldAlternate(fav, company)) ||
          (isBaskCompany(company) && fav.type === "med")) &&
        // variableFavs.includes(switchFavFieldAlternate(fav, company)) &&
        matchedPref
      ) {
        return matchedPref.quantity.toString();
      } else if (
        visitType.includes("jaseCasePeds") &&
        isPref &&
        fav.clientName === "9GLzkmuhdxgamJz5mlXNBABEF6NVuiCl" &&
        !!weight
      ) {
        const quantSwitch = (weight) => {
          const numWeight = parseFloat(weight);
          switch (true) {
            case numWeight > 50 && numWeight <= 88:
              return "20";
            default:
              return "10";
          }
        };
        return quantSwitch(weight);
      } else if (
        company === "jase" &&
        (visitType.includes("chronicMed") ||
          ["jaseCase", "jaseCaseCanada", "jaseCaseGo", "jaseCaseJase"].includes(
            visitType
          )) &&
        isPref &&
        matchedPref
      ) {
        return matchedPref.quantity.toString();
      } else return fav.quantity.toString();
    };

    const switchRefills = (company, fav, isPref) => {
      if (
        (isBaskCompany(company) || variableFavCompanies.includes(company)) &&
        isPref &&
        (variableFavs.includes(switchFavFieldAlternate(fav, company)) ||
          (isBaskCompany(company) && fav.type === "med")) &&
        // variableFavs.includes(switchFavFieldAlternate(fav, company)) &&
        matchedPref
      ) {
        const intRefills = parseInt(matchedPref.refills);
        if (fav.visitType === "weightloss" && intRefills > 3) {
          return 3;
        }
        return intRefills;
      } else if (company === "readyRx" && matchedPref && isPref) {
        const intRefills = parseInt(matchedPref.refills);
        return intRefills;
      } else return fav.refills;
    };

    const switchDaysSupply = (company, visitType, fav, isPref) => {
      if (
        variableFavCompanies.includes(company) &&
        isPref &&
        variableFavs.includes(switchFavFieldAlternate(fav, company)) &&
        matchedPref &&
        visitType.includes("weightloss")
      ) {
        const daysSupply = parseInt(matchedPref.daysSupply || fav.daysSupply);
        return daysSupply.toString();
      } else return fav.daysSupply.toString();
    };

    const switchStrength = (visitType, fav, isPref) => {
      if (
        visitType.includes("jaseCasePeds") &&
        isPref &&
        fav.clientName === "9GLzkmuhdxgamJz5mlXNBABEF6NVuiCl" &&
        !!weight
      ) {
        const strengthSwitch = (weight) => {
          const numWeight = parseFloat(weight);
          switch (true) {
            case numWeight > 33 && numWeight <= 50:
              return "45 mg";
            case numWeight > 88:
              return "75 mg";
            default:
              return "30 mg";
          }
        };
        const newStrength = fav.strength.replace(
          "{variable}",
          strengthSwitch(weight)
        );
        return newStrength;
      } else return fav.strength;
    };

    const switchSig = (visitType, fav, isPref) => {
      if (visitType.includes("jaseCasePeds") && isPref && !!weight) {
        const weightSwitch = () => {
          const numWeight = parseFloat(weight);
          if (fav.clientName === "J1qiP52Pt2oDUnvLdfWsIAhS9YTdAFNa") {
            switch (true) {
              case parseFloat(weight) < 35:
                return "1";
              case parseFloat(weight) >= 35 && parseFloat(weight) < 46:
                return "1.5";
              default:
                return "2";
            }
          } else if (fav.clientName === "9GLzkmuhdxgamJz5mlXNBABEF6NVuiCl") {
            switch (true) {
              case numWeight > 50 && numWeight <= 88:
                return "two capsules";
              default:
                return "one capsule";
            }
          }
        };
        const newSig = fav.sig.replace("{variable}", weightSwitch(weight));
        return newSig;
      } else if (visitType.includes("chronicMed") && isPref && matchedPref) {
        const { pillsPerDose, frequency } = matchedPref;
        const newSig = fav.sig
          .replace("{pillsPerDose}", pillsPerDose)
          .replace("{frequency}", frequency);
        return newSig;
      } else return fav.sig;
    };

    if (
      simplePrefs.find((pref) => pref.name === switchFavField(fav, company))
    ) {
      isPatientPreference = true;
    } else {
      if (/^bask/.test(company) && fav.type === "med") {
        const foundPref = patientPreference.find((pref) => {
          const isMatchStrength =
            parseFloat(pref.strength) === parseFloat(fav.strength);
          const isMatchName = fav.favoriteName.includes(
            pref.name.split(" ")[0]
          );
          return isMatchName && isMatchStrength;
        });
        if (foundPref) {
          isPatientPreference = true;
        }
      } else if (visitType === "abortion") {
        isPatientPreference = false;
      }
    }

    return {
      isPatientPreference,
      selected: isPatientPreference,
      name: fav.medication,
      daysSupply: switchDaysSupply(
        company,
        visitType,
        fav,
        isPatientPreference
      ),
      quantity: switchQuantity(company, visitType, fav, isPatientPreference),
      refills: switchRefills(company, fav, isPatientPreference),
      unit: fav.dispenseUnit,
      strength: switchStrength(visitType, fav, isPatientPreference),
      allowSubstitution: fav.allowSubstitutions,
      pharmacyNotes: `${switchPharmNotes(
        masterId,
        company,
        visitType,
        rxHistory,
        diagnosis,
        pharmacyId,
        email,
        fav.baskName,
        rxNotes
      )}${fav.pharmacyNotes || ""}`,
      directions: switchSig(visitType, fav, isPatientPreference),
      favoriteName: fav.favoriteName,
      clientName: fav.clientName,
      baskName: fav.baskName,
      supplyId: fav.supplyId,
      type: fav.type,
      category: fav.category,
    };
  });

  if (!patientPreference) {
    return selectableMedications;
  }

  if (
    weightlossNamedFieldsCompanies.includes(company) &&
    visitType.includes("weightloss")
  ) {
    // create switch to handle all combos of currentWeightloss, weightlossPreference, currentDose
    const desiredCategorySwitch = () => {
      const categories = [
        "Weightloss1",
        "Weightloss2",
        "Weightloss3",
        "Weightloss4",
        "Weightloss5",
      ];
      const currentDoseIdx = categories.indexOf(currentDose);
      switch (true) {
        // neither -> starting dose
        case currentWeightloss === "neither":
          return "Weightloss1";
        // increase, currentDose 5 -> 5
        case currentWeightloss !== "neither" &&
          weightlossPreference === "increase" &&
          currentDose === "Weightloss5":
          return "Weightloss5";
        // increase, currentDose not 5 -> next dose
        case currentWeightloss !== "neither" &&
          weightlossPreference === "increase" &&
          currentDose !== "Weightloss5":
          return categories[currentDoseIdx + 1];
        // decrease, currentDose 1 -> 1
        case currentWeightloss !== "neither" &&
          weightlossPreference === "decrease" &&
          currentDose === "Weightloss1":
          return "Weightloss1";
        // decrease, currentDose not 1 -> previous dose
        case currentWeightloss !== "neither" &&
          weightlossPreference === "decrease" &&
          currentDose !== "Weightloss1":
          return categories[currentDoseIdx - 1];
        // same -> return currentDose
        case currentWeightloss !== "neither" && weightlossPreference === "same":
          return currentDose;
        default:
          return "Weightloss1";
      }
    };
    const desiredCategory = desiredCategorySwitch();
    // filter favs to only match desiredCategory
    const favFieldToMatch = isBaskCompany ? "baskName" : "clientName";
    const prefFieldToMatch = isBaskCompany ? "packageNDC" : "medId";
    const filteredToMatchDesired = selectableMedications.filter(
      (fav) => fav.category === desiredCategory && !!fav[favFieldToMatch]
    );

    // within filtered, if incoming pref match, select incoming pref match
    const matchedCorrectPref = filteredToMatchDesired.find((fav) =>
      patientPreference
        .map((pref) => pref[prefFieldToMatch])
        .includes(fav[favFieldToMatch])
    );
    const matchedIncorrectPrefs = selectableMedications.filter(
      (fav) =>
        patientPreference
          .map((pref) => pref[prefFieldToMatch])
          .includes(fav[favFieldToMatch]) &&
        fav.category !== "syringe" &&
        fav.category !== "antiNausea"
    );
    if (matchedCorrectPref) {
      matchedCorrectPref.selected = true;
    } else {
      // if incoming pref no match, select random
      matchedIncorrectPrefs.forEach((fav) => (fav.selected = false));
      // filter favoriteName including month or week
      const noMultiProducts = filteredToMatchDesired.filter((fav) => {
        const isNotMulti = !/month|week/i.test(fav.favoriteName);
        const isMed = fav.type === "med";
        const isPrefMed = matchedIncorrectPrefs.some(
          (pref) => pref.type === "med"
        );
        const switchType = () => {
          switch (true) {
            case isMed && isPrefMed:
            case !isMed && !isPrefMed:
              return true;
            case isMed && !isPrefMed:
            case !isMed && isPrefMed:
            default:
              return false;
          }
        };
        return isNotMulti && switchType();
      });
      if (noMultiProducts.length > 0) {
        noMultiProducts[0].selected = true;
      }
    }
  }

  return selectableMedications;
};

export const fullDSVisitTypes = [
  "weightloss",
  "weightlossfollowup",
  "weightmaintenance",
  "antiAging",
  "hairloss",
  "ED",
  "fungus",
  "weightlossCA",
  "weightlossfollowupCA",
  "stiTest",
  "nipple",
  "acne",
  "ubacareChronicMed",
  "ubacareChronicMedFollowup",
  "menstrual",
  "cgm",
  "cvRisk",
  "alcohol",
  "latisse",
  "antiNausea",
  "PE",
  "quell",
  "travel",
  "hsv",
  "womenSexualHealth",
  "libido",
  "hyperhydrosis",
  "abortion",
  "prep",
  "hyperPigmentation",
  "metabolicSupport",
  "ozioKit",

  // Jase visitTypes:
  "jaseCase",
  "jaseCasePeds",
  "jaseCasePedsCanada",
  "jaseCaseCanada",
  "jaseCaseGo",
  "jaseCaseJase",
  "chronicMed",

  // Populus visitTypes:
  "phexxi",
  "xifaxan",
  "trulance",
  "linzess",
  "mytesi",
  "cerefolinnac",
  "airSupra",
  "deplin",
  "qbrexza",
  "nurtec",
  "jublia",
];

export const fullDSCompanies = [
  "Populus",
  "myDrHank",
  "247Health",
  "upForLove",
  "bask",
  "smartDoctors",
  "aspectHealth",
  "betterWeightForLife",
  "jase",
  "dosespotTesting",
  "myoRx",
  "ilMakiage",
  "bodygoodstudio",
  "motivated",
  "mitochondria",
  "poshdoc",
  "orderlyMeds",
  "katherineCary",
  "dosable",
  "venus",
  "architek",
  "jackHealth",
  "maion",
  "gulaworld",
  "urway",
  "pureLife",
  "foundation",
  "siphox",
  "ilMeds",
  "medisuite",
  "know",
  "neweigh",
  "lifebac",
  "ubacare",
  "fitScripts",
  "neurometrix",
  "newHopeWellness",
  "kmish",
  "manyLabs",
  "zepmeds",
  "newHopeWellness",
  "employeemd",
  "mailMyMeds",
  "readyRx",
  "fitpal",
  "drDerm",
  "emed",
  "oatMeds",
  "genRx",
  "undefined",
  "uplift",
  "totalMaleRx",
  "healthHavenRx",
  "doctorApprovedMeds",
  "braveRx",
];

export const switchFullDS = (company, visitType) => {
  switch (true) {
    case fullDSVisitTypes.includes(visitType) &&
      fullDSCompanies.some((name) => company.includes(name)):
      return true;
    default:
      return false;
  }
};

export const pdfEmailCompanies = ["ilMeds", "baskMangoTelehealthInc", "jase"];

export const pdfEmailVisitType = [
  "weightloss",
  "weightlossfollowup",
  "weightlossCA",
  "weightlossfollowupCA",
  "jaseCasePedsCanada",
  "jaseCaseCanada",
  "jaseCaseJase",
];

export const compoundPharms = [
  "243478", //hallandale
  "251162", //red rock
  "250720", //red rock alt
  "75990", //red rock slc
  "240931", //anazao
  "269229", //nova
  "265585", //precision
  "255863", //rxCompoundStore
  "250664", //revive
  "254283", //shields
  "239792", //empower
  "272200", //txpromed
  "266280", //vive
  "38580", //junglejims
  // "258819", //drhank
  "74186", //chemrx
  "271235", //MFV
  "255546", //strive
  "41717", //boothwyn
  "45162", //midtown
  "271235", //mfv
  "255546", //strive
  "239252", //southend
  "73382", //wells
  "72323", //belmar
  "73586", //pharmalabs
  "8453", //promise
];
