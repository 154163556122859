import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import AllPatients from "../AllPatients/AllPatients";
import ActivePendingPatients from "../ActivePendingPatients/ActivePendingPatients";
import DocCard from "../DocCard/DocCard";
import NewUserForm from "./NewUserForm";
import SearchBar from "./SearchBar";

import { states, stateAbbrevs } from "../DataFiles/states";
import useInterval from "../useInterval";
import { routerMap } from "../utils/router";
import { timeAt } from "../utils/helperFunctions";

import companyIcon from "../Assets/Icon/company.png";
import createIcon from "../Assets/Icon/createUser.png";
import arrowDropdownIcon from "../Assets/Icon/arrowDropdown.png";
import calendarIcon from "../Assets/Icon/calendar.png";
import userIcon from "../Assets/Icon/user.png";
import stateIcon from "../Assets/Icon/states.png";
import xIcon from "../Assets/Icon/x@2x.png";

import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";

import "./MyAccount.css";

import "react-datepicker/dist/react-datepicker.css";

const MyAccount = (props) => {
  const {
    user,
    admin,
    currentPatient,
    setCurrentPatient,
    checkLogin,
    dsUrl,
    setDsUrl,
    transErrors,
    setTransErrors,
    companies,
    setCompanies,
  } = props;
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [filteredPatientsAdmin, setFilteredPatientsAdmin] = useState([]);
  const [docs, setDocs] = useState([]);
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [resolvedDocVisits, setResolvedDocVisits] = useState([]);
  // const [resolvedDocVisits, setResolvedDocVisits] = useState(
  //   user.resolvedVisits
  // );
  const [filteredResolvedDocVisits, setFilteredResolvedDocVisits] = useState(
    []
  );
  const [show, setShow] = useState(false);
  const [fullResolved, setFullResolved] = useState([]);
  const [filteredResolved, setFilteredResolved] = useState([]);
  const [filteredReferred, setFilteredReferred] = useState(0);
  const [filteredPrescribed, setFilteredPrescribed] = useState(0);
  const [filteredVideo, setFilteredVideo] = useState(0);
  const [fullAutoClosed, setFullAutoClosed] = useState([]);
  const [filteredAutoClosed, setFilteredAutoClosed] = useState([]);
  const [isAdminLoading, setIsAdminLoading] = useState(false);
  const [isActivePendingLoading, setIsActivePendingLoading] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isFilteredLoading, setIsFilteredLoading] = useState(false);

  const token = localStorage.getItem("jwt");

  const today = new Date();

  const endOfToday = (date) => {
    const newDate = new Date(date);
    newDate.setUTCHours(11, 59, 59, 999);
    return newDate;
  };

  const addMonths = (date, months) => {
    const d = date.getUTCDate();
    date.setUTCMonth(date.getUTCMonth() + months);
    date.setUTCHours(0, 0, 0, 1);
    if (date.getUTCDate() !== d) {
      date.setUTCDate(0);
    }
    return date;
  };

  // const [docStartDate, setDocStartDate] = useState(addMonths(new Date(), -1));
  // const [docEndDate, setDocEndDate] = useState(new Date());
  const [visitStartDate, setVisitStartDate] = useState(
    addMonths(new Date(), -1)
  );
  const [visitEndDate, setVisitEndDate] = useState(endOfToday(today));
  const [resolveStartDate, setResolveStartDate] = useState(
    addMonths(new Date(), -1)
  );
  const [resolveEndDate, setResolveEndDate] = useState(endOfToday(today));
  const [docStateFilter, setDocStateFilter] = useState({
    name: "All States",
    value: null,
  });
  const [docCompanyFilter, setDocCompanyFilter] = useState("All Companies");
  const [visitCompanyFilter, setVisitCompanyFilter] = useState("All Companies");
  const [visitTypeFilter, setVisitTypeFilter] = useState("All");
  const [visitDoctorFilter, setVisitDoctorFilter] = useState({
    name: "All Doctors",
    value: null,
  });
  const [adminDocTimeLog, setAdminDocTimeLog] = useState(null);
  const [showResolvedList, setShowResolvedList] = useState(false);

  const {
    getPatients,
    getPatientsAdmin,
    getFilteredPatients,
    getDoctors,
    getCompanies,
    getResolvedFull,
    getAutoClosed,
    getTransmissionErrors,
    getUserResolved,
  } = routerMap;

  const intervalDelay = 1000 * 60 * 15;

  const labCompanies = ["labme", "oneHealthLabs"];

  const inactiveCompanies = [
    "scoreBlue",
    "sprout",
    "metuchen",
    "revyv",
    "rxCompoundStore",
    "powerplate",
    "medsForMen",
    "oneHealthLabs",
    "sunrise",
  ];

  const onlyActiveCompanies = companies.filter(
    (company) => !inactiveCompanies.includes(company.name)
  );

  const handleGetTransErrors = async () => {
    await getTransmissionErrors(token, user, setTransErrors, setDsUrl);
  };

  // refetch transmission errors every 15 minutes
  useInterval(handleGetTransErrors, intervalDelay);

  const handleClose = () => {
    setShow(false);
  };

  const openUserModal = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const filterDoctorsCompany = (e) => {
    setDocCompanyFilter(e.target.value);
  };

  const filterDoctorsState = (e) => {
    setDocStateFilter({
      name: e.target.name,
      value: e.target.value,
    });
  };

  const filterVisitsCompany = (e) => {
    setVisitCompanyFilter(e.target.value);
  };

  const filterVisitsType = (e) => {
    setVisitTypeFilter(e.target.value);
  };

  const filterVisitsDoctor = (e) => {
    setVisitDoctorFilter({
      name: e.target.name,
      value: e.target.value,
    });
  };

  const handleActivePendingRefresh = async () => {
    setIsActivePendingLoading(true);
    await getPatients(token, setPatients);
    setIsActivePendingLoading(false);
  };

  const handleFetchResolvedFull = async () => {
    setIsAdminLoading(true);
    await getResolvedFull(token, setFullResolved, setFilteredResolved);
    setIsAdminLoading(false);
  };

  const handleFetchUserResolved = async () => {
    setIsUserLoading(true);
    await getUserResolved(
      token,
      setResolvedDocVisits,
      setFilteredResolvedDocVisits
    );
    setIsUserLoading(false);
  };

  const handleShowResolvedList = () => {
    setShowResolvedList(true);
  };

  const handleHideResolvedList = () => {
    setShowResolvedList(false);
  };

  // on component mount
  useEffect(() => {
    setCurrentPatient({});
    const asyncFilteredPatients = async () => {
      setIsFilteredLoading(true);
      await getFilteredPatients(token, user, setFilteredPatients);
      setIsFilteredLoading(false);
    };
    asyncFilteredPatients();
    if (user.dosespotID && !dsUrl) {
      handleGetTransErrors();
    }
    const fetchLogin = async () => {
      await checkLogin();
    };
    fetchLogin();
  }, []);

  useEffect(() => {
    setVisitStartDate(addMonths(new Date(), -1));
    setVisitEndDate(endOfToday(today));
    setResolveStartDate(addMonths(new Date(), -1));
    setResolveEndDate(endOfToday(today));
    if (user.admin && admin) {
      setPatients([]);
      const allPromises = async () => {
        setIsAdminLoading(true);
        await getPatientsAdmin(token, setFilteredPatientsAdmin);
        await getCompanies(token, setCompanies);
        if (user.systemAdmin) {
          await Promise.all([
            getDoctors(token, setDocs, setFilteredDocs),
            getAutoClosed(token, setFullAutoClosed, setFilteredAutoClosed),
          ]);
        }
        setIsAdminLoading(false);
      };
      allPromises();
    }
  }, [admin, user]);

  useEffect(() => {
    const filteredRef = filteredResolved.filter(
      (visit) =>
        visit.resolvedTimestamp && visit.isRefer && !visit.videoTimestamp
    );
    const filteredPres = filteredResolved.filter(
      (visit) =>
        visit.resolvedTimestamp &&
        "isRefer" in visit &&
        !visit.isRefer &&
        !visit.videoTimestamp
    );
    const filteredVideo = filteredResolved.filter(
      (visit) => visit.resolvedTimestamp && visit.videoTimestamp
    );

    setFilteredReferred(filteredRef.length);
    setFilteredPrescribed(filteredPres.length);
    setFilteredVideo(filteredVideo.length);
  }, [filteredResolved]);

  // doc filters
  useEffect(() => {
    let filtered;
    if (
      docCompanyFilter !== "All Companies" &&
      docStateFilter.name !== "All States"
    ) {
      filtered = docs.filter(
        (doc) =>
          doc.stateLicenses.includes(docStateFilter.value) &&
          doc.companies.includes(docCompanyFilter)
      );
    } else if (
      docCompanyFilter === "All Companies" &&
      docStateFilter.name === "All States"
    ) {
      filtered = docs;
    } else if (docCompanyFilter === "All Companies") {
      filtered = docs.filter((doc) =>
        doc.stateLicenses.includes(docStateFilter.value)
      );
    } else if (docStateFilter.name === "All States") {
      filtered = docs.filter((doc) => doc.companies.includes(docCompanyFilter));
    }
    setFilteredDocs(filtered);
  }, [docCompanyFilter, docStateFilter]);

  // Admin Visits Filters
  useEffect(() => {
    const startOfDay = new Date(visitStartDate);
    startOfDay.setUTCHours(0, 0, 0, 1);

    const startVal = startOfDay.valueOf();
    const endOfDay = endOfToday(visitEndDate);
    const endVal = endOfDay.valueOf();

    const filterFn = (visit, time) => {
      const visitVal = new Date(time).valueOf();
      switch (true) {
        case visitCompanyFilter === "All Companies" &&
          visitDoctorFilter.name === "All Doctors" &&
          visitTypeFilter === "All":
          return visitVal >= startVal && visitVal <= endVal;
        case visitCompanyFilter === "All Companies" &&
          visitDoctorFilter.name !== "All Doctors" &&
          visitTypeFilter === "All":
          return (
            visitVal >= startVal &&
            visitVal <= endVal &&
            visit.closingDoc === visitDoctorFilter.value
          );
        case visitCompanyFilter !== "All Companies" &&
          visitDoctorFilter.name === "All Doctors" &&
          visitTypeFilter === "All":
          return (
            visitVal >= startVal &&
            visitVal <= endVal &&
            visit.company === visitCompanyFilter
          );
        case visitCompanyFilter === "All Companies" &&
          visitDoctorFilter.name === "All Doctors" &&
          visitTypeFilter !== "All":
          return (
            visitVal >= startVal &&
            visitVal <= endVal &&
            visit.visitType === visitTypeFilter
          );
        case visitCompanyFilter !== "All Companies" &&
          visitDoctorFilter.name === "All Doctors" &&
          visitTypeFilter !== "All":
          return (
            visitVal >= startVal &&
            visitVal <= endVal &&
            visit.company === visitCompanyFilter &&
            visit.visitType === visitTypeFilter
          );
        case visitCompanyFilter === "All Companies" &&
          visitDoctorFilter.name !== "All Doctors" &&
          visitTypeFilter !== "All":
          return (
            visitVal >= startVal &&
            visitVal <= endVal &&
            visit.closingDoc === visitDoctorFilter.value &&
            visit.visitType === visitTypeFilter
          );
        case visitCompanyFilter !== "All Companies" &&
          visitDoctorFilter.name !== "All Doctors" &&
          visitTypeFilter === "All":
          return (
            visitVal >= startVal &&
            visitVal <= endVal &&
            visit.company === visitCompanyFilter &&
            visit.closingDoc === visitDoctorFilter.value
          );
        default:
          return (
            visitVal >= startVal &&
            visitVal <= endVal &&
            visit.company === visitCompanyFilter &&
            visit.visitType === visitTypeFilter &&
            visit.closingDoc === visitDoctorFilter.value
          );
      }
    };

    const dateFiltered = fullResolved
      .filter((visit) => filterFn(visit, visit.resolvedTimestamp))
      .filter((visit) => visit.visitType !== "filesToReview");
    setFilteredResolved(dateFiltered);
    const dateFilteredAutoClosed = fullAutoClosed.filter((visit) =>
      filterFn(visit, visit.autoClosedTimestamp)
    );
    setFilteredAutoClosed(dateFilteredAutoClosed);

    // doctor time logging
    const foundDoc = docs.find((doc) => doc._id === visitDoctorFilter.value);
    const timeLogs = foundDoc?.timeTracking;
    const filteredTimes = timeLogs?.filter((log) => {
      const startBounded = new Date(log.start).getTime() > startVal;
      const endBounded = new Date(log.stop).getTime() < endVal;
      return startBounded && endBounded;
    });
    const totalTime = filteredTimes
      ?.map((log) => {
        const datedStart = new Date(log.start).valueOf();
        const datedStop = new Date(log.stop).valueOf();
        const added = !isNaN(datedStop) ? datedStop - datedStart : 0;
        return added;
      })
      .reduce((acc, cur) => acc + cur, 0);
    const rounded = Math.ceil(totalTime / (1000 * 60)); // ms * s * m * h
    setAdminDocTimeLog(rounded);
  }, [
    docs,
    fullAutoClosed,
    fullResolved,
    visitStartDate,
    visitEndDate,
    visitCompanyFilter,
    visitDoctorFilter,
    visitTypeFilter,
  ]);

  // Clinician Resolved Visits Filters
  useEffect(() => {
    const startOfDay = resolveStartDate;
    startOfDay.setUTCHours(0, 0, 0, 0);
    const endOfDay = endOfToday(resolveEndDate);
    const dateFiltered = resolvedDocVisits.filter((visit) => {
      // const dateFiltered = user.resolvedVisits.filter((visit) => {
      const visitResolvedDate = new Date(visit.resolvedTimestamp);
      const visitVal = visitResolvedDate.valueOf();
      const result = visitVal >= startOfDay && visitVal <= endOfDay;
      return result;
    });
    setFilteredResolvedDocVisits(dateFiltered);
  }, [resolveStartDate, resolveEndDate, resolvedDocVisits]);

  const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button onClick={onClick} className="filterButton dateButton">
      <img src={calendarIcon} alt="calendar" />
      {value.slice(0, -5)}
    </button>
  ));

  const datePicker = (fn, selected) => {
    return (
      <DatePicker
        selected={selected}
        onChange={(date) => fn(date)}
        customInput={<DateButton />}
        showPopperArrow={false}
        popperPlacement="top-end"
      />
    );
  };

  const visitCompaniesArray = onlyActiveCompanies
    .map((company) => company.name)
    .sort()
    .map((companyName) => {
      return (
        <Dropdown.Item
          as="button"
          name={companyName}
          value={companyName}
          key={companyName}
          onClick={filterVisitsCompany}
        >
          {companyName}
        </Dropdown.Item>
      );
    });

  const visitTypesArray = [
    ...new Set(
      onlyActiveCompanies
        .map((company) =>
          company.visitTypes.map((visitType) => visitType.type).flat()
        )
        .flat()
    ),
  ]
    .sort()
    .map((visitType) => {
      return (
        <Dropdown.Item
          as="button"
          name={visitType}
          value={visitType}
          key={visitType}
          onClick={filterVisitsType}
        >
          {visitType}
        </Dropdown.Item>
      );
    });

  const doctorCompaniesArray = onlyActiveCompanies
    .map((company) => company.name)
    .sort()
    .map((companyName) => {
      return (
        <Dropdown.Item
          as="button"
          name={companyName}
          value={companyName}
          key={companyName}
          onClick={filterDoctorsCompany}
        >
          {companyName}
        </Dropdown.Item>
      );
    });

  const doctorsArray = docs.map((doc) => {
    return (
      <Dropdown.Item
        as="button"
        name={`${doc.firstName} ${doc.lastName}`}
        value={doc._id}
        key={doc._id}
        onClick={filterVisitsDoctor}
      >
        {doc.firstName} {doc.lastName}
      </Dropdown.Item>
    );
  });

  const docList = [
    ...filteredDocs
      .filter((doc) => doc.lastActiveTimestamp)
      .sort(
        (a, b) =>
          new Date(b.lastActiveTimestamp) - new Date(a.lastActiveTimestamp)
      ),
    ...filteredDocs.filter((doc) => !doc.lastActiveTimestamp),
  ].map((doc, docIndex) => {
    return <DocCard doc={doc} companies={onlyActiveCompanies} key={docIndex} />;
  });

  const stateListLocal = states.map((state) => {
    return (
      <Dropdown.Item
        as="button"
        name={state}
        value={stateAbbrevs[states.indexOf(state)]}
        key={state}
        onClick={filterDoctorsState}
      >
        {state}
      </Dropdown.Item>
    );
  });

  const resolvedList = filteredResolved
    .sort(
      (a, b) => new Date(b.resolvedTimestamp) - new Date(a.resolvedTimestamp)
    )
    .slice(0, 50)
    .map((visit) => {
      const { company, resolvedTimestamp, masterId } = visit;
      return (
        <div key={visit._id} id={visit._id} className="listCard searchCard">
          <div className="wrapText">{masterId}</div>
          <div>{company}</div>
          <div>{timeAt(resolvedTimestamp)}</div>
        </div>
      );
    });

  return (
    <div className="accountContainer">
      <div className="overviewContainer">
        {!isAdminLoading && (
          <div className="listFiltersRow">
            {!admin ? (
              <div className="listTitleText">Overview</div>
            ) : (
              <div className="listTitleText">Visit Overview</div>
            )}
            {user.systemAdmin && admin ? (
              <>
                <div>
                  <span className="filterLabel">Doctor</span>
                  <Dropdown>
                    <Dropdown.Toggle as="button" className="filterButton">
                      <img src={userIcon} alt="userIcon" />
                      {visitDoctorFilter.name}
                      <img src={arrowDropdownIcon} alt="arrowDown" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="filterMenu">
                      <Dropdown.Item
                        as="button"
                        name="All Doctors"
                        value={null}
                        onClick={filterVisitsDoctor}
                      >
                        All Doctors
                      </Dropdown.Item>
                      {doctorsArray}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  <span className="filterLabel">Company</span>
                  <Dropdown>
                    <Dropdown.Toggle as="button" className="filterButton">
                      <img src={companyIcon} alt="companyIcon" />
                      {visitCompanyFilter}
                      <img src={arrowDropdownIcon} alt="arrowDown" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="filterMenu">
                      <Dropdown.Item
                        as="button"
                        name="All Companies"
                        value="All Companies"
                        onClick={filterVisitsCompany}
                      >
                        All Companies
                      </Dropdown.Item>
                      {visitCompaniesArray}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  <span className="filterLabel">Visit Type</span>
                  <Dropdown>
                    <Dropdown.Toggle as="button" className="filterButton">
                      {visitTypeFilter}
                      <img src={arrowDropdownIcon} alt="arrowDown" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="filterMenu">
                      <Dropdown.Item
                        as="button"
                        name="All"
                        value="All"
                        onClick={filterVisitsType}
                      >
                        All Visit Types
                      </Dropdown.Item>
                      {visitTypesArray}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="datePickerContainer">
                  {datePicker(setVisitStartDate, visitStartDate)}-
                  {datePicker(setVisitEndDate, visitEndDate)}
                </div>
              </>
            ) : (
              <div className="datePickerContainer">
                {datePicker(setResolveStartDate, resolveStartDate)}-
                {datePicker(setResolveEndDate, resolveEndDate)}
              </div>
            )}
          </div>
        )}
        <div className="overviewRow">
          {admin ? (
            user.systemAdmin && (
              <>
                {visitDoctorFilter.name === "All Doctors" && (
                  <div className="overviewItem">
                    <h5>Active/Pending</h5>
                    {isActivePendingLoading ? (
                      <div>Loading...</div>
                    ) : patients.length > 0 ? (
                      <ActivePendingPatients
                        patients={patients}
                        currentPatient={currentPatient}
                        setCurrentPatient={setCurrentPatient}
                        visitCompanyFilter={visitCompanyFilter}
                      />
                    ) : (
                      <div>None</div>
                    )}
                    <button
                      className="activePendingButton"
                      onClick={handleActivePendingRefresh}
                    >
                      Refresh
                    </button>
                  </div>
                )}
                {visitDoctorFilter.name !== "All Doctors" && (
                  <div className="overviewItem leftBorder">
                    <h5>Time log</h5>
                    {isAdminLoading ? (
                      <div>Loading...</div>
                    ) : (
                      <>
                        {Math.floor(adminDocTimeLog / 60)} hours,{" "}
                        {adminDocTimeLog % 60} minutes
                      </>
                    )}
                  </div>
                )}

                <div className="overviewItem leftBorder">
                  <h5>
                    Filtered Resolved (
                    <span className="dashLink" onClick={handleShowResolvedList}>
                      {!isAdminLoading &&
                        filteredResolved.length - filteredVideo}
                    </span>
                    )
                  </h5>
                  <div className="forcedRow">
                    <button
                      className="visitButton"
                      onClick={handleFetchResolvedFull}
                    >
                      {isAdminLoading ? "Loading..." : "Get Resolved Visits"}
                    </button>
                    {isAdminLoading ? (
                      <div>Loading...</div>
                    ) : (
                      <div>
                        Referred: {filteredReferred} <br />
                        Prescribed: {filteredPrescribed}
                      </div>
                    )}
                  </div>
                </div>
                {(labCompanies.includes(visitCompanyFilter) ||
                  visitCompanyFilter === "hio") && (
                  <div className="overviewItem leftBorder">
                    <h5>
                      Filtered Auto Closed (
                      {!isAdminLoading && filteredAutoClosed.length})
                    </h5>
                  </div>
                )}
                {visitCompanyFilter === "jase" && (
                  <div className="overviewItem leftBorder">
                    <h5>Filtered Video ({!isAdminLoading && filteredVideo})</h5>
                  </div>
                )}
              </>
            )
          ) : (
            <>
              <div className="overviewItem">
                <h5>Your resolved visits</h5>
                {filteredResolvedDocVisits.length > 0 && (
                  <div>{filteredResolvedDocVisits.length}</div>
                )}
                <button
                  className="visitButton"
                  onClick={handleFetchUserResolved}
                >
                  {isUserLoading ? "Loading..." : "Get Resolved Visits"}
                </button>
              </div>
              {user.dosespotID && (
                <div className="overviewItem leftBorder">
                  <h5>Dosespot Notifications</h5>
                  <a
                    href={dsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dashLink"
                  >
                    {transErrors}
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="listContainer">
        <div className="listFiltersRow">
          {admin && <div className="listTitleText">Admin attention needed</div>}
          {!admin && (
            <div className="listTitleText">
              Actionable Patients ({filteredPatients.length})
            </div>
          )}
          <SearchBar
            user={user}
            patients={filteredPatients}
            admin={admin}
            setCurrentPatient={setCurrentPatient}
          />
        </div>
        <div className="listTitleRow listLabels">
          <div>PATIENT NAME</div>
          {admin ? (
            <div>VISIT UPDATED TIMESTAMP</div>
          ) : (
            <div>VISIT CREATION TIMESTAMP</div>
          )}
          {admin && <div>ADMIN VISIT COMPANY</div>}
          {!admin && <div>ACTIVE VISIT COMPANIES</div>}
        </div>
        <Row>
          <Col className="scrollCards">
            {admin &&
              (filteredPatientsAdmin.length ? (
                <AllPatients
                  user={user}
                  patients={filteredPatientsAdmin}
                  currentPatient={currentPatient}
                  setCurrentPatient={setCurrentPatient}
                  listType={"nondocAdmin"}
                />
              ) : (
                <>
                  {isAdminLoading && <h5>Loading...</h5>}
                  {!isAdminLoading && <h5>No Patients</h5>}
                </>
              ))}
            {!admin && isFilteredLoading ? (
              <h5>Loading Patients...</h5>
            ) : filteredPatients.length ? (
              <AllPatients
                user={user}
                patients={filteredPatients}
                currentPatient={currentPatient}
                setCurrentPatient={setCurrentPatient}
              />
            ) : (
              <h5>No Patients</h5>
            )}
          </Col>
        </Row>
      </div>
      {admin && (
        <>
          <div className="listContainer">
            <div className="listFiltersRow">
              <div className="listTitleText">
                Clinician admin attention needed
              </div>
            </div>
            <div className="listTitleRow listLabels">
              <div>PATIENT NAME</div>
              <div>VISIT UPDATED TIMESTAMP</div>
              <div>ADMIN VISIT COMPANY</div>
            </div>
            <Row>
              <Col className="scrollCards">
                {filteredPatientsAdmin.length ? (
                  <AllPatients
                    user={user}
                    patients={filteredPatientsAdmin}
                    currentPatient={currentPatient}
                    setCurrentPatient={setCurrentPatient}
                    listType={"docAdmin"}
                  />
                ) : isAdminLoading ? (
                  <h5>Loading...</h5>
                ) : (
                  <h5>No Patients</h5>
                )}
              </Col>
            </Row>
          </div>

          <div className="listContainer">
            <div className="listFiltersRow">
              <div className="listTitleText">Super admin attention needed</div>
            </div>
            <div className="listTitleRow listLabels">
              <div>PATIENT NAME</div>
              <div>VISIT UPDATED TIMESTAMP</div>
              <div>ADMIN VISIT COMPANY</div>
            </div>
            <Row>
              <Col className="scrollCards">
                {filteredPatientsAdmin.length ? (
                  <AllPatients
                    user={user}
                    patients={filteredPatientsAdmin}
                    currentPatient={currentPatient}
                    setCurrentPatient={setCurrentPatient}
                    listType={"superAdmin"}
                  />
                ) : isAdminLoading ? (
                  <h5>Loading...</h5>
                ) : (
                  <h5>No Patients</h5>
                )}
              </Col>
            </Row>
          </div>

          <div className="listContainer">
            <div className="listFiltersRow">
              <div className="listTitleText">Tech support attention needed</div>
            </div>
            <div className="listTitleRow listLabels">
              <div>PATIENT NAME</div>
              <div>VISIT UPDATED TIMESTAMP</div>
              <div>ADMIN VISIT COMPANY</div>
            </div>
            <Row>
              <Col className="scrollCards">
                {filteredPatientsAdmin.length ? (
                  <AllPatients
                    user={user}
                    patients={filteredPatientsAdmin}
                    currentPatient={currentPatient}
                    setCurrentPatient={setCurrentPatient}
                    listType={"techSupport"}
                  />
                ) : isAdminLoading ? (
                  <h5>Loading...</h5>
                ) : (
                  <h5>No Patients</h5>
                )}
              </Col>
            </Row>
          </div>

          {!!user.systemAdmin && (
            <div className="listContainer">
              <div className="listFiltersRow">
                <div className="listTitleText">Doctors</div>
                <div>
                  <span className="filterLabel">Company</span>
                  <Dropdown>
                    <Dropdown.Toggle as="button" className="filterButton">
                      <img src={companyIcon} alt="companyIcon" />
                      {docCompanyFilter}
                      <img src={arrowDropdownIcon} alt="arrowDown" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="filterMenu">
                      <Dropdown.Item
                        as="button"
                        name="All Companies"
                        value="All Companies"
                        onClick={filterDoctorsCompany}
                      >
                        All Companies
                      </Dropdown.Item>
                      {doctorCompaniesArray}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  <span className="filterLabel">State</span>
                  <Dropdown>
                    <Dropdown.Toggle as="button" className="filterButton">
                      <img src={stateIcon} alt="mapIcon" />
                      {docStateFilter.name}
                      <img src={arrowDropdownIcon} alt="arrowDown" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="filterMenu">
                      <Dropdown.Item
                        as="button"
                        name="All States"
                        value={null}
                        onClick={filterDoctorsState}
                      >
                        All States
                      </Dropdown.Item>
                      {stateListLocal}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <button onClick={openUserModal} className="createButton">
                  <img
                    src={createIcon}
                    className="createIcon"
                    alt="Create User Icon"
                  />
                  Create User
                </button>
              </div>
              <div className="listTitleRow listLabels">
                <div>DOCTOR NAME</div>
                <div>RESOLVED VISITS</div>
                <div>LAST RESOLVED</div>
              </div>
              <div className="scrollCards">{docs.length > 0 && docList}</div>
            </div>
          )}
        </>
      )}

      {user.systemAdmin && admin && (
        <>
          <Modal show={show} onHide={handleClose} dialogClassName="modalDims">
            <div className="modalHeader">
              <div className="modalTitle">
                <span>Create a new user</span>
                <button onClick={handleClose} className="modalCloseButton">
                  <img src={xIcon} className="xIcon" alt="close" />
                </button>
              </div>
            </div>
            <NewUserForm closeModal={handleClose} companies={companies} />
          </Modal>

          <Modal
            show={showResolvedList}
            onHide={handleHideResolvedList}
            dialogClassName="modalDims"
          >
            <div className="modalHeader">
              <div className="modalTitle">
                <span>
                  Filtered Resolved Visits ({visitDoctorFilter.name},{" "}
                  {visitCompanyFilter}, VisitType: {visitTypeFilter})
                </span>
                <button
                  onClick={handleHideResolvedList}
                  className="modalCloseButton"
                >
                  <img src={xIcon} className="xIcon" alt="close" />
                </button>
              </div>
            </div>
            <div className="listTitleRow listLabels">
              <div>MASTERID</div>
              <div>COMPANY</div>
              <div>RESOLVED</div>
            </div>
            {resolvedList.length ? (
              resolvedList
            ) : (
              <div key={"None"} id={"None"} className="listCard searchCard">
                <div>None</div>
              </div>
            )}
          </Modal>
        </>
      )}
    </div>
  );
};

export default MyAccount;
