import React, { useEffect, useState, Fragment } from "react";
import Viewer from "react-viewer";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import ReactPlayer from "react-player";
import DOMPurify from "dompurify";

import ResolveContainer from "../ResolveContainer/ResolveContainer";
import MessagingContainer from "../MessagingContainer/MessagingContainer";
import PatientMedicationList from "../Components/PatientMedicationList";

import xIcon from "../Assets/Icon/x@2x.png";
import arrowDropdownIcon from "../Assets/Icon/arrowDropdown@2x.png";
// import { fullRejectPhotoText } from '../DataFiles/rxTexts';
import { routerMap } from "../utils/router";
import { injectedNote } from "../utils/noteUtils";
import { trackAdminTimeOnly } from "../utils/timeTrackingUtils";
import useVisitLogs from "../hooks/useVisitLogs";

import "./Visit.css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const options = { workerSrc: "/pdf.worker.js" };

const isDaybreakApneaVideo = (visit) => {
  return visit.company === "daybreak" && visit.visitType === "apneaVideo";
};

const Visit = (props) => {
  const {
    patient: {
      phone,
      chat,
      _id,
      firstName,
      lastName,
      allergies,
      medicationsPrescribed,
    },
    visit,
    user,
    allNotes,
    updateVisit,
    updatePatient,
    setCurrentVisit,
    admin,
    visitResolutionText,
    setVisitResolutionText,
    visitInternalChat,
    isPatientLoading,
    setIsPatientLoading,
    isHourly,
  } = props;
  const [note, setNote] = useState("");
  const [chatState, setChatState] = useState(chat);
  const [activeMainTab, setActiveMainTab] = useState(
    admin ? "messages" : "idPhotos"
  );
  const [prescriptionSent, setPrescriptionSent] = useState(
    visit.prescriptionSent
  );
  const [error, setError] = useState(false);
  const [imageVisible, setImageVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [currentVideo, setCurrentVideo] = useState("");
  const [showCSModal, setShowCSModal] = useState(false);
  const [CSMessage, setCSMessage] = useState({});
  const [idPhotos, setIdPhotos] = useState([]);
  const [isPhotosLoading, setIsPhotosLoading] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectingPhoto, setRejectingPhoto] = useState("");
  const [rejectError, setRejectError] = useState("");
  const [gettingChats, setGettingChats] = useState(false);
  const [isAdminResolve, setIsAdminResolve] = useState(false);
  const [orders, setOrders] = useState([]);

  const [showChatModal, setShowChatModal] = useState(false);
  const [internalError, setInternalError] = useState("");
  const [internalMessage, setInternalMessage] = useState({
    senderId: user._id,
    senderName: `${user.firstName} ${user.lastName}`,
    senderType: "belugaAdmin",
    content: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [orderChats, setOrderChats] = useState([]);
  const [isChatPending, setIsChatPending] = useState(false);
  const [hioOutcome, setHioOutcome] = useState({
    outcome: "",
    note: "",
  });
  const [hioResolve, setHioResolve] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isCSLoading, setIsCSLoading] = useState(false);
  const [photosToUpload, setPhotosToUpload] = useState([]);
  const [showPhotoUploadModal, setShowPhotoUploadModal] = useState(false);
  const [isVideoDone, setIsVideoDone] = useState(!!visit.videoTimestamp);
  const [docs, setDocs] = useState([]);
  const [currentDoc, setCurrentDoc] = useState("");
  const [rotatePdf, setRotatePdf] = useState(0);

  const { visitLogs } = useVisitLogs(visit.masterId);

  const token = localStorage.getItem("jwt");

  const mainAdminId =
    process.env.REACT_APP_STAGE === "prod"
      ? "5f923ff775230b0008abacf4"
      : "5e8d4b904e3f47f93c07b01c";
  const cancelAdminIds =
    process.env.REACT_APP_STAGE === "prod"
      ? [
          "60788df59a4edc00085b349e",
          "64907134c27f2fe1d5ba777a",
          "5f923ff775230b0008abacf4",
          "65c3bd4634ce6a0822aecb43",
          "65bf59bc248e76343ce8aa37",
          "5f923a0323ac76dbbbb5bfff",
        ]
      : ["5e8d4b904e3f47f93c07b01c", "5e8d3c7b34e4e4f3673b8822"];

  const ecommerceCompanies = ["upForLove"];
  // const labCompanies = [
  //   "labme",
  //   "rupaLabs",
  //   // "baskIvyRx",
  //   // "baskMojoonInc",
  //   // "baskYourxHealth",
  //   // "baskMedicology",
  //   // "baskRell",
  //   // "baskDudeMeds",
  //   "baskHevaHealth",
  //   "siphox",
  //   "healthHavenRx",
  // ];

  const {
    getOrders,
    sendChat,
    sendText,
    sendPhotoRequestSMS,
    sendCSMessage,
    getPhotos,
    getVideo,
    getChatHistory,
    uploadPhotosAdmin,
    rejectIdPhoto,
    updateDocTime,
    cancelVisit,
    logEvent,
    getDoctorsTrimmed,
    csCompanies,
    csApiList,
    updateVisitVideoStatus,
  } = routerMap;

  const canResolve =
    (visit.rxHistory?.length > 0 || visit.isRefer || prescriptionSent) &&
    visit.note.length > 0 &&
    !visit.resolvedTimestamp;

  const handleRotate = () => {
    setRotatePdf((rotatePdf + 90) % 360);
  };

  const Tab = (props) => {
    const { title, onTabClick, activeTab, label } = props;
    const tabClass = `tabStyle ${label === activeTab ? "activeTab" : ""}`;

    const handleClick = () => {
      onTabClick(label);
    };
    // const stickyTab =
    //   <div className='stickyWrapper'>
    //     <button
    //       className={tabClass}
    //       onClick={handleClick}
    //       title={title}
    //       value={label}
    //     >
    //       {title}

    //     </button>
    //     <div className="unreadCSmessages" />
    //   </div>;
    const regTab = (
      <button
        className={tabClass}
        onClick={handleClick}
        title={title}
        value={label}
      >
        {title}
      </button>
    );
    // const result = title === 'Orders' && orders.length > 0 && orders.find(order => order.status === 'admin')
    //   ?
    //     stickyTab
    //   :
    //     regTab;
    const result = regTab;
    return result;
  };

  const Tabs = (props) => {
    const { activeMainTab, onSelect, children } = props;

    const tabArray = children.map((child) => {
      if (child) {
        return (
          <Tab
            key={child.props.title}
            title={child.props.title}
            onTabClick={onSelect}
            activeTab={activeMainTab}
            label={child.props.label}
          />
        );
      } else {
        return null;
      }
    });

    const contentArray = children.map((child) => {
      if (child && child.props.label === activeMainTab) {
        return child.props.children;
      } else {
        return null;
      }
    });

    return (
      <>
        <div className="tabList">{tabArray}</div>
        <div className="tabContentsContainer">{contentArray}</div>
      </>
    );
  };

  const openChatModal = (e) => {
    e.preventDefault();
    setShowChatModal(true);
  };

  const handleCloseChat = () => {
    setShowChatModal(false);
    setInternalError("");
  };

  const handleMessageChange = (e) => {
    setInternalMessage({
      ...internalMessage,
      content: e.target.value,
    });
  };

  const handleResolve = async () => {
    try {
      await logEvent(token, "sendAndResolve", {
        userId: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        visitType: visit.visitType,
        company: visit.company,
        masterId: visit.masterId,
      });
      let data = {
        visitUpdate: {
          status: "resolved",
          currentDoc: null,
        },
      };
      if (visit.closingDoc) {
        await updateVisit(visit._id, data);
      } else {
        data = {
          visitUpdate: {
            ...data.visitUpdate,
            closingDoc: admin ? visit.currentDoc || mainAdminId : user._id,
            updateTimestamp: new Date(),
            resolvedTimestamp: new Date(),
          },
        };
        await updateVisit(visit._id, data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleResolveHio = async () => {
    try {
      await logEvent(token, "sendAndResolve", {
        userId: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        visitType: visit.visitType,
        company: visit.company,
        masterId: visit.masterId,
      });
      let data = {
        visitUpdate: {
          status: "resolved",
        },
      };
      if (visit.closingDoc) {
        await updateVisit(visit._id, data);
      } else {
        const now = new Date();
        const writer = `${user.firstName} ${user.lastName}`;
        const newNote = `${now}:noteTime ${writer}:noteWriter ${hioOutcome.outcome}: ${hioOutcome.note}:noteBody`;
        data = {
          outcome: hioOutcome.outcome,
          note: hioOutcome.outcome === "opt-out" ? hioOutcome.note : "N/A",
          visitUpdate: {
            ...data.visitUpdate,
            note: [...visit.note, newNote],
            closingDoc: visit.currentDoc,
            updateTimestamp: new Date(),
            resolvedTimestamp: new Date(),
          },
        };
        await updateVisit(visit._id, data);
      }
      setHioResolve(false);
      changeTab("formData");
    } catch (err) {
      console.log(err);
    }
  };

  const handleSendText = async (message) => {
    setIsPatientLoading(true);
    if (isHourly) {
      await updateDocTime(token);
    }
    await sendText(
      token,
      visit,
      _id,
      admin,
      message,
      setError,
      setChatState,
      updatePatient,
      setCurrentVisit,
      canResolve,
      handleResolve
      // setCanResolve
    );
    setIsPatientLoading(false);
  };

  const handleNotesChange = (data) => {
    setNote(data);
  };

  const handleHioNoteChange = (data) => {
    setHioOutcome({
      ...hioOutcome,
      note: data,
    });
    setNote(data);
  };

  const handleHioOutcomeChange = (data) => {
    setHioOutcome({
      ...hioOutcome,
      outcome: data,
      note: "",
    });
  };

  const handleCancel = async () => {
    await logEvent(token, "adminCancel", {
      userId: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      visitType: visit.visitType,
      company: visit.company,
      masterId: visit.masterId,
    });
    await cancelVisit(token, _id, visit, phone, updatePatient);
  };

  const handlePhotoRequest = async () => {
    const photoMessage = {
      to: phone,
      body: `Hi ${firstName}, this is your Jase Medical doctor.
In order to confirm the accuracy of your prescription, please reply to this text message with clear photos of your existing prescription labels.`,
    };
    await sendPhotoRequestSMS(
      token,
      visit,
      _id,
      admin,
      photoMessage,
      setError,
      setChatState,
      updatePatient,
      setCurrentVisit
    );
  };

  const changeTab = async (tab) => {
    setActiveMainTab(tab);
    if (tab === "messages") {
      if (isHourly) {
        await updateDocTime(token);
      }
      await getChatHistory(token, _id, setGettingChats, setChatState);
    }
  };

  const adminChangeStatus = async (e) => {
    const status = e.target.id;
    let data = {
      visitUpdate: {
        status: status,
        updateTimestamp: new Date(),
      },
    };
    if (status !== "admin") {
      data = {
        visitUpdate: {
          ...data.visitUpdate,
          isClinicianAdmin: false,
          isSuperAdmin: false,
          isTechSupport: false,
        },
      };
    }
    if (status === "resolved") {
      data = {
        visitUpdate: {
          ...data.visitUpdate,
          currentDoc: null,
        },
      };
    }
    await logEvent(token, `adminChangeStatus_${status}`, {
      userId: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      visitType: visit.visitType,
      company: visit.company,
      masterId: visit.masterId,
    });
    await updateVisit(visit._id, data);
  };

  const handleClosePdf = () => {
    setPageNumber(1);
    setShowPdfModal(false);
  };

  const openPdfViewer = (e) => {
    setCurrentImage(e.currentTarget.id);
    setShowPdfModal(true);
  };

  const openPhotoViewer = async (e) => {
    if (isHourly) {
      await updateDocTime(token);
    }
    setCurrentImage(e.target.src);
    setImageVisible(true);
  };

  const closePhotoViewer = () => {
    setImageVisible(false);
  };

  const openCSModal = () => {
    setShowCSModal(true);
  };

  const closeCSModal = () => {
    setShowCSModal(false);
    setCSMessage({
      ...CSMessage,
      content: "",
    });
  };

  const handleCSChange = (e) => {
    setCSMessage({
      ...CSMessage,
      content: e.target.value,
    });
  };

  const handleCSSubmit = async () => {
    setIsCSLoading(true);
    await logEvent(token, "adminSendCS", {
      userId: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      visitType: visit.visitType,
      company: visit.company,
      masterId: visit.masterId,
    });

    await trackAdminTimeOnly(user, admin, token);

    await sendCSMessage(
      token,
      CSMessage,
      setShowCSModal,
      setCSMessage,
      visit,
      firstName,
      lastName,
      phone
    );
    setIsCSLoading(false);
  };

  const handleCSMarkRead = async () => {
    const data = {
      visitUpdate: {
        isCSMessagePending: false,
      },
    };

    await trackAdminTimeOnly(user, admin, token);

    await updateVisit(visit._id, data);
    setShowCSModal(false);
  };

  const handleSendInternalChat = async () => {
    if (internalMessage.content === "") {
      setInternalError("Please type a message before sending!");
      return;
    }

    await trackAdminTimeOnly(user, admin, token);

    const messageWithDate = { ...internalMessage, createdAt: new Date() };
    const data = {
      message: messageWithDate,
    };
    const correctOrder = orders
      .filter((order) => order.status === "admin")
      .sort(
        (a, b) => new Date(a.createTimestamp) - new Date(b.createTimestamp)
      )[0];
    setInternalError("");
    setSubmitting(true);
    await sendChat(token, data, correctOrder._id, setOrders);
    setSubmitting(false);
    setInternalMessage({
      ...internalMessage,
      content: "",
    });
    setShowChatModal(false);
  };

  const handleApprove = async () => {
    const data = {
      visitUpdate: {
        photosApproved: {
          approved: true,
          approveTime: new Date(),
        },
      },
    };
    if (isHourly) {
      await updateDocTime(token);
    }
    await updateVisit(visit._id, data);
  };

  const resetApprove = async () => {
    const data = {
      visitUpdate: {
        photosApproved: {
          approved: false,
          approveTime: null,
        },
      },
    };
    await updateVisit(visit._id, data);
  };

  const resetCurrentDoc = async (e) => {
    const { name } = e.target;
    const data = {
      visitUpdate: {
        currentDoc: name === "None" ? null : name,
      },
    };
    await updateVisit(visit._id, data);
  };

  const manualResolve = async () => {
    await logEvent(token, "adminManualResolve", {
      userId: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      visitType: visit.visitType,
      company: visit.company,
      masterId: visit.masterId,
    });
    setIsAdminResolve(true);
  };

  const onDocumentLoadSuccess = ({ numPages: pdfPageNumber }) => {
    setNumPages(pdfPageNumber);
  };

  const previousPdfPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const nextPdfPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleUploadPhotos = async () => {
    await uploadPhotosAdmin(
      token,
      visit._id,
      photosToUpload,
      setCurrentVisit,
      updatePatient
    );
  };

  const handleOpenPhotoUploadModal = () => {
    setShowPhotoUploadModal(true);
  };

  const handleClosePhotoUploadModal = () => {
    setPhotosToUpload([]);
    setShowPhotoUploadModal(false);
  };

  const handleRemovePhoto = (e) => {
    setPhotosToUpload((photosToUpload) =>
      photosToUpload.filter((photo) => photo.filename !== e.target.name)
    );
  };

  const handlePhoto = async (e) => {
    setError("");
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      // if (isHeicImageFile(file)) {
      //   await convertAndReadHeic(file, reader);
      // } else {
      //   reader.readAsDataURL(file);
      // }
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        const imgElement = document.createElement("img");
        imgElement.src = e.target.result;
        imgElement.onload = (imgE) => {
          const canvas = document.createElement("canvas");
          const maxWidth = 1000 > imgE.target.width ? imgE.target.width : 1000;
          const scaleSize = maxWidth / imgE.target.width;
          canvas.width = maxWidth;
          canvas.height = imgE.target.height * scaleSize;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(imgE.target, 0, 0, canvas.width, canvas.height);

          const encoded = ctx.canvas.toDataURL("image/jpeg");
          const stripped = encoded.replace("data:image/jpeg;base64,", "");
          setPhotosToUpload([
            ...photosToUpload,
            {
              filename: file.name,
              encodedString: stripped,
              fullSrc: encoded,
            },
          ]);
        };
      };
    } else {
      return;
    }
  };

  const handleRejectClick = (e) => {
    setShowRejectModal(true);
    setRejectingPhoto(e.target.name);
  };

  const closeRejectModal = () => {
    setShowRejectModal(false);
  };

  const handleReject = async () => {
    setIsPatientLoading(true);
    const rejectError = await rejectIdPhoto(
      token,
      visit,
      rejectingPhoto,
      setShowRejectModal
    );
    if (rejectError) {
      setRejectError("Something went wrong, please try to reject photo again.");
    }
    setIsPatientLoading(false);
  };

  const handleVisitNoShow = async () => {
    const data = {
      status: "NO_SHOW",
    };
    await updateVisitVideoStatus(token, visit._id, data, setCurrentVisit);
  };

  const handleVideoDoneClick = async () => {
    const now = new Date();
    const data = {
      visitUpdate: {
        videoTimestamp: now,
      },
    };
    await updateVisit(visit._id, data);
    setIsVideoDone(true);
  };

  const middleDot = "\u00B7";

  const timeAt = (time) => {
    const then = new Date(time);
    let hours = then.getHours();
    let ampm = "am";
    if (hours >= 12) {
      if (hours === 24 || hours === 12) {
        hours = 12;
      } else {
        hours = hours % 12;
      }
      ampm = "pm";
    }
    let minutes = then.getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    const month = then.getMonth() + 1;
    const date = then.getDate();
    const year = then.getFullYear();
    const prettyThen = `${month}/${date}/${year} ${hours}:${minutes}${ampm}`;
    return prettyThen;
  };

  const notesList = allNotes.flat().map((noteItem, idx) => {
    let noteTime,
      noteWriter,
      noteBody = null;
    const timeIdx = noteItem.search(":noteTime ");
    const writerIdx = noteItem.search(":noteWriter ");
    const bodyIdx = noteItem.search(":noteBody");
    if (timeIdx !== -1) {
      noteTime = noteItem.slice(0, timeIdx);
    }
    if (writerIdx !== -1) {
      noteWriter = noteItem.slice(timeIdx + 10, writerIdx);
    }
    if (bodyIdx !== -1) {
      noteBody = noteItem.slice(writerIdx + 12, bodyIdx);
    } else {
      noteBody = noteItem;
    }
    return (
      <Fragment key={idx}>
        <div key={idx} className="noteContainer">
          {noteTime && noteWriter && (
            <div className="noteMetadata">
              {timeAt(noteTime)} {middleDot} {noteWriter}
            </div>
          )}
          <div key={idx} className="noteBody">
            {noteBody}
          </div>
        </div>
      </Fragment>
    );
  });

  const formObjRows = () => {
    const data = visit.formObj;
    const mappedKeysGlobal = {
      consentsSigned: "Consents for telemedicine and privacy policy signed?",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      dob: "Date of Birth",
      sex: "Sex",
      phone: "Phone Number",
      address: "Address",
      state: "State",
      city: "City",
      zip: "Zip",
      weight: "Weight",
      patientPreference: "Patient preference",
      patientPref: "Patient preference",
      selfReportedMeds: "Self-reported Meds",
      allergies: "Allergies",
      medicalConditions: "Medical Conditions",
      icdCode: "ICD Code",
      condition: "Condition",
      externalTestWithinLastYear: "Test Within Last Year",
      language: "Language",
      diagnosis: "Diagnosis",
      currentDose: "Current Dose",
      currentWeightloss: "Current Weightloss Med",
      weightlossPreference: "Weightloss Change",
      programTypes: "Program Types",
      conditions: "Conditions",
    };
    if (data) {
      const dataKeys = Object.keys(data);
      const regexQ = RegExp(/Q[0-9]+/);
      let qArray = [];
      for (let i = 0; i < dataKeys.length; i++) {
        if (regexQ.test(dataKeys[i])) {
          qArray.push(dataKeys[i]);
        }
      }

      const unmappedDataRows = qArray.map((k) => {
        const num = k.slice(1);
        const questionText = data[`Q${num}`];
        const answerText = data[`A${num}`]
          ? data[`A${num}`].toString()
          : "NO ANSWER PROVIDED";
        const idxPossibles = questionText.search("POSSIBLE ANSWERS: ");
        const formattedA = answerText.split(";");
        const answersList = formattedA.map((answer, idx) => {
          return (
            <li
              key={idx}
              className={
                answer === "NO ANSWER PROVIDED" ? "redBold abnormal" : ""
              }
            >
              <small>{answer}</small>
            </li>
          );
        });
        if (questionText && idxPossibles > 0) {
          const prePossibles = questionText.slice(0, idxPossibles);
          const formattedQ = questionText
            .slice(idxPossibles + 18, questionText.length)
            .split(";");
          const possiblesList = formattedQ.map((possible, idx) => (
            <li key={idx}>
              <small>{possible}</small>
            </li>
          ));
          return (
            <tr key={`${k}${num}`}>
              <td>
                {prePossibles}
                <br />
                {possiblesList}
              </td>
              <td>{answersList}</td>
            </tr>
          );
        } else {
          return (
            <tr key={`${k}${num}`}>
              <td>{data[`Q${num}`]}</td>
              <td>{answersList}</td>
            </tr>
          );
        }
      });
      const qaTest = RegExp(/[QA][0-9]+/);
      const filtered = Object.keys(data).filter(
        (k) => !qaTest.test(k) && !!mappedKeysGlobal[k]
      );
      const mappedDataRows = filtered.map((key, kIndex) => {
        let formattedA;
        if (data[key] === undefined || data[key] === null) {
          formattedA = "";
        } else if (!!data[key] || !!data[key].toString()) {
          if (Array.isArray(data[key])) {
            formattedA = data[key]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((pref) => {
                return (
                  <div key={data[key][0].name}>
                    {Object.entries(pref).map((kvArray) => {
                      return (
                        <div key={kvArray[1]}>
                          {kvArray[0]}: {kvArray[1]}
                        </div>
                      );
                    })}
                  </div>
                );
              });
          } else if (typeof data[key] === "object") {
            formattedA = Object.entries(data[key]).map((kvArray) => {
              return `${kvArray[0]}: ${kvArray[1]}`;
            });
          } else {
            formattedA = data[key].toString().split(";");
          }
        } else {
          formattedA = "";
        }

        const answersList =
          formattedA.length > 1
            ? formattedA.map((answer, idx) => (
                <li key={idx}>
                  <small>{answer}</small>
                </li>
              ))
            : formattedA[0];
        return (
          <tr key={kIndex}>
            <td>{mappedKeysGlobal[key]}</td>
            <td>{answersList}</td>
          </tr>
        );
      });
      return [...mappedDataRows, ...unmappedDataRows];
    }
    return null;
  };

  const adminStatuses = [
    "active",
    "pending",
    "resolved",
    "admin",
    "holding",
  ].map((status, index) => {
    return (
      <li key={index}>
        <button onClick={adminChangeStatus} id={status} className="adminButton">
          {status}
        </button>
      </li>
    );
  });

  const idPhotoCards = idPhotos.length ? (
    idPhotos.map((photo, idx) => {
      return (
        <Fragment key={photo.name}>
          {photo.name.slice(-3) === "pdf" ? (
            <>
              <div
                id={photo.src}
                onClick={openPdfViewer}
                className="pdfContainer"
              >
                <Document
                  file={photo.src}
                  onLoadSuccess={onDocumentLoadSuccess}
                  rotate={rotatePdf}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
              </div>
              <button onClick={handleRotate} className="patientButton">
                Rotate
              </button>
              {numPages > 1 && (
                <div className="pdfButtons">
                  <button onClick={previousPdfPage} className="pdfButton">
                    Previous Page
                  </button>
                  <button onClick={nextPdfPage} className="pdfButton">
                    Next Page
                  </button>
                </div>
              )}
            </>
          ) : (
            <div key={photo.name} className="photoCard">
              <img
                src={photo.src}
                className="imageSizing"
                onClick={openPhotoViewer}
                alt={photo.name}
              />
              <div className="photoIndex">{idx}</div>
              {visit.company === "motivated" && (
                <button
                  className="rejectPhotoButton"
                  onClick={handleRejectClick}
                  name={photo.name}
                  value={photo.name}
                  disabled={
                    visit.photosApproved ? visit.photosApproved.approved : false
                  }
                >
                  Reject
                </button>
              )}
            </div>
          )}
        </Fragment>
      );
    })
  ) : (
    <div>No ID photos here!</div>
  );
  const visitPhotoCards = visit.visitPics.length
    ? visit.visitPics.map((url, idx) => {
        return (
          <div key={idx} className="photoCard">
            <img
              src={url}
              className="imageSizing"
              onClick={openPhotoViewer}
              alt={url}
            />
          </div>
        );
      })
    : null;
  const reverseCSArray = visit.customerServiceChat.slice().reverse();

  const sanitizedHTML = (html) => {
    return DOMPurify.sanitize(html);
  };

  const customerServiceArray = visit.customerServiceChat
    ? reverseCSArray.map(
        ({ senderType, senderName, content, timestamp }, idx) => {
          const style = senderType === "beluga" ? "belugaEmail" : "csEmail";
          const containerStyle =
            senderType === "beluga"
              ? "belugaEmailContainer"
              : "csEmailContainer";
          const whoSent = senderType === "beluga" ? "Beluga" : visit.company;
          return (
            <div key={idx} className={containerStyle}>
              <div
                className={style}
                dangerouslySetInnerHTML={{ __html: sanitizedHTML(content) }}
              />
              {timeAt(timestamp)} {!!senderName && senderName} ({whoSent})
            </div>
          );
        }
      )
    : null;

  const chatBubbleClass = (message) => {
    let className = `chatBubble internalChat${message.senderType} tightMessage`;
    if (message.senderType === "belugaAdmin") {
      className += " messageRight";
    } else {
      className += " messageLeft";
    }
    return className;
  };

  const populateChat = () => {
    const chats = orders
      .filter((order) => order.adminChat && order.adminChat.length > 0)
      .map((order) => order.adminChat)
      .flat();
    const renderChats = chats.map((message) => {
      const messageDate = new Date(message.createdAt);
      const prettyDate = `${
        messageDate.getMonth() + 1
      }-${messageDate.getDate()}-${messageDate.getFullYear()}`;
      return (
        <li key={message._id} className={chatBubbleClass(message)}>
          <p>{message.content}</p>
          <small>
            Sent by {message.senderName},{" "}
            {message.senderType === "businessAdmin"
              ? "Business Admin"
              : "Beluga Admin"}
          </small>
          <small>Sent on {prettyDate}</small>
        </li>
      );
    });
    const isAdminChatPending =
      chats.length && chats[chats.length - 1].senderType === "businessAdmin"
        ? true
        : false;
    setIsChatPending(isAdminChatPending);
    return renderChats;
  };

  const resultsArray = (
    <>
      <tr className="resultsHeader">
        <td>Screening Date</td>
        <td>Test Name</td>
        <td>Test Result</td>
        <td>Units</td>
        <td>Reference Range</td>
        <td>Status Indicator</td>
        <td>Device Type</td>

        {/* {Object.keys(visit.labResults[0]).map(key => {
            return(
              <td>{key}</td>
            );
          })} */}
      </tr>
      {visit.labResults.map((result) => {
        const isAbnormal = result.statusIndicator !== "N";
        const cardClass = isAbnormal ? "resultRow abnormal" : "resultRow";
        const statusIndicator = isAbnormal ? (
          <span className="redBold">{result.statusIndicator}</span>
        ) : (
          <span>{result.statusIndicator}</span>
        );
        return (
          <tr key={result.testName} className={cardClass}>
            <td>{result.screeningDate}</td>
            <td>{result.testName}</td>
            <td>{result.testResult}</td>
            <td>{result.testResultUnits}</td>
            <td>{result.refRange}</td>
            <td>{statusIndicator}</td>
            <td>{result.deviceType}</td>
          </tr>
        );
      })}
    </>
  );

  const hioResults = () => {
    if (visit.company === "hio" && visit.results) {
      const { results, resultsTimestamp: time } = visit;
      const dated = new Date(time);
      const prettyDate = `${
        dated.getMonth() + 1
      }-${dated.getDate()}-${dated.getFullYear()}`;
      const isAbnormal = results !== "normal";
      const cardClass = isAbnormal ? "resultRow abnormal" : "resultRow";
      const statusIndicator = isAbnormal ? (
        <span className="redBold">{results}</span>
      ) : (
        <span>{results}</span>
      );
      return (
        <>
          <tr className="resultsHeader">
            <td>Result Date</td>
            <td>Result</td>
          </tr>
          <tr key={`${results}${time}`} className={cardClass}>
            <td>{!!time ? prettyDate : "Unknown"}</td>
            <td>{statusIndicator}</td>
          </tr>
        </>
      );
    } else {
      return <tr>No results</tr>;
    }
  };

  const jasePatientPreference =
    visit.company === "jase" ? (
      <>
        <tr className="tableRowBorder stickyHeader">
          <td>Quant</td>
          <td>#/dose</td>
          <td>Freq</td>
        </tr>
        {visit.formObj.patientPreference.map((pref) => {
          return (
            <>
              <tr className="tableRowBorder" key={`${pref.name}1`}>
                <td className="tableMed">{pref.name}</td>
              </tr>
              <tr className="tableRowBorder bottomBorder" key={`${pref.name}2`}>
                <td>{pref.quantity}</td>
                <td>{pref.pillsPerDose}</td>
                <td>{pref.frequency}</td>
              </tr>
            </>
          );
        })}
      </>
    ) : null;

  const switchVideoDone = () => {
    switch (true) {
      case visit.company === "jase" && visit.formObj.state === "MS":
      case visit.company === "ubacare" &&
        visit.visitType === "ubacareChronicMedSync":
      case isDaybreakApneaVideo(visit):
      case visit.company === "respiri":
        return isVideoDone;
      default:
        return true;
    }
  };

  const docList = docs.map((doc) => {
    return (
      <Dropdown.Item onClick={resetCurrentDoc} name={doc._id}>
        {doc.firstName} {doc.lastName}
      </Dropdown.Item>
    );
  });

  const asyncGetDocs = async () => {
    await getDoctorsTrimmed(token, visit, setDocs);
  };

  const switchResults = () => {
    switch (visit.company) {
      case "daybreak":
        return visit.results;
      case "hio":
        return hioResults();
      default:
        return resultsArray;
    }
  };

  useEffect(() => {
    setPrescriptionSent(visit.prescriptionSent);
    setNote(
      injectedNote(visit.visitType, user, {
        medicationsPrescribed,
        allergies,
        programTypes: visit.formObj.programTypes,
      })
    );
    setShowPhotoUploadModal(false);
    setPhotosToUpload([]);
    setIsVideoDone(!!visit.videoTimestamp);
    setDocs([]);
    setCSMessage({
      firstName,
      lastName,
      phone,
      visitId: visit._id,
      content: "",
      company: visit.company,
    });
    const asyncPhotos = async () =>
      await getPhotos(token, visit._id, setIdPhotos, setIsPhotosLoading);
    asyncPhotos();
    if (user.admin && admin) {
      asyncGetDocs();
    }
    if (visit.company === "goodyHealth") {
      const asyncVideo = async () =>
        await getVideo(token, visit._id, setCurrentVideo);
      asyncVideo();
    }
    if (user.admin && admin && ecommerceCompanies.includes(visit.company)) {
      const allAsync = async () => {
        await Promise.all([
          getOrders(token, visit, setOrders),
          // getProducts(visit, setSKUs)
        ]);
      };
      allAsync();
    }

    if (visit.resolveText && !visit.resolvedTimestamp) {
      setVisitResolutionText(visit.resolveText);
    }
    if (!admin) {
      if (
        visit.note.length &&
        visit.rxHistory?.length &&
        visit.photosApproved.approved
      ) {
        setActiveMainTab("messages");
      }
      if (!visit.note.length && visit.photosApproved.approved) {
        setActiveMainTab("formData");
      }
      if (!visit.photosApproved.approved) {
        setActiveMainTab("idPhotos");
      }
    }
  }, [visit]);

  useEffect(() => {
    if (docs.length && visit.currentDoc) {
      const foundDoc = docs.filter(
        (doc) => doc._id.toString() === visit.currentDoc.toString()
      )[0];
      if (!foundDoc) {
        const data = {
          visitUpdate: {
            currentDoc: null,
          },
        };
        const asyncVisitUpdate = async () => {
          await updateVisit(visit._id, data);
        };
        asyncVisitUpdate();
      } else {
        setCurrentDoc(`${foundDoc.firstName} ${foundDoc.lastName}`);
      }
    } else {
      setCurrentDoc("None");
    }
  }, [docs]);

  useEffect(() => {
    if (orders.length) {
      const chat = populateChat();
      setOrderChats(chat);
    }
  }, [orders]);

  return (
    <div className="visitContent">
      <div className="tabContainer">
        <Tabs
          activeMainTab={activeMainTab}
          transition={false}
          id="visitTabs"
          onSelect={changeTab}
          className="tabs"
        >
          <div
            label="idPhotos"
            name="idPhotos"
            title="Images"
            tabClassName="tabStyle"
          >
            <div className="tabContentContainer">
              {visit.company === "jase" &&
                ["chronicMed"].includes(visit.visitType) && (
                  <button onClick={handlePhotoRequest} className="chatButton">
                    Request photos
                  </button>
                )}
              {visit.photosApproved && visit.photosApproved.approved ? (
                <div className="photosApproved">ID Verified</div>
              ) : (
                <button
                  onClick={handleApprove}
                  className="approveButton"
                  disabled={!idPhotos.length && !visit.visitPics.length}
                >
                  Verify ID
                </button>
              )}

              <div className="photoSubContent">
                {visit.company === "jase" &&
                  visit.visitType === "chronicMed" && (
                    <div className="jaseTable">
                      <table>
                        <tbody>{jasePatientPreference}</tbody>
                      </table>
                    </div>
                  )}
                <div className="photoContainer">
                  {isPhotosLoading ? (
                    <div>Photos loading...</div>
                  ) : (
                    <>
                      {idPhotoCards}
                      {visitPhotoCards}
                    </>
                  )}
                </div>
              </div>
              {visit.company === "goodyHealth" && (
                <div>
                  <ReactPlayer url={currentVideo} controls />
                </div>
              )}
            </div>
          </div>
          <div label="formData" title="Patient Data" tabClassName="tabStyle">
            <div className="formObjContainer tabContentContainer">
              <table>
                <tbody>{formObjRows()}</tbody>
              </table>
            </div>
          </div>
          <div label="messages" title="Messages" tabClassName="tabStyle">
            <div className="tabContentContainer">
              <MessagingContainer
                user={user}
                phoneNumber={phone}
                visit={visit}
                chat={chatState}
                handleSendText={handleSendText}
                updateVisit={updateVisit}
                error={error}
                visitResolutionText={visitResolutionText}
                // isRejecting={isRejecting}
                // rejectText={rejectText}
                canResolve={canResolve}
                gettingChats={gettingChats}
                updateDocTime={updateDocTime}
                isHourly={isHourly}
              />
            </div>
          </div>
          <div label="notes" title="Notes Log" tabClassName="tabStyle">
            <div className="tabContentContainer">{notesList}</div>
          </div>
          <div
            label="patient-medications"
            title="Medications"
            tabClassName="tabStyle"
          >
            <div className="tabContentContainer">
              <PatientMedicationList
                user={user}
                visitId={visit._id}
                patientId={_id}
              />
            </div>
          </div>
          {user.admin && admin && (
            <div label="admin" title="Admin" tabClassName="tabStyle">
              <div className="tabContentContainer flex-row">
                <div className="tabContentContainer">
                  <button
                    onClick={handleOpenPhotoUploadModal}
                    className="visitButton"
                  >
                    Upload visit photos
                  </button>

                  <h5>Manually change visit status</h5>
                  <ul className="ulNoStyle">{adminStatuses}</ul>
                  <h5>Manually reset photo approval</h5>
                  <button onClick={resetApprove} className="adminButton">
                    Reset photo approval
                  </button>
                  <h5>Current doctor: {currentDoc}</h5>
                  <Dropdown title={"Reset current doc"}>
                    <Dropdown.Toggle className="docDrop" id="docDrop">
                      {"Change current doc"}
                      <img src={arrowDropdownIcon} alt="arrowDown" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdownMenu">
                      <Dropdown.Item onClick={resetCurrentDoc} name={"None"}>
                        None
                      </Dropdown.Item>
                      {docList}
                    </Dropdown.Menu>
                  </Dropdown>
                  {!visit.resolvedTimestamp && (
                    <>
                      <h5>Manually resolve visit</h5>
                      <button onClick={manualResolve} className="adminButton">
                        Resolve visit
                      </button>
                      {!prescriptionSent &&
                        cancelAdminIds.includes(user._id) && (
                          <>
                            <h5>CANCEL VISIT</h5>
                            <button
                              onClick={handleCancel}
                              className="adminButton"
                            >
                              Cancel visit
                            </button>
                          </>
                        )}
                    </>
                  )}
                </div>
                <div className="tabContentContainer visitEventLogsContainer">
                  {visitLogs.length
                    ? visitLogs.map((log) => (
                        <div key={log.logTimestamp} className="visitLog">
                          <p>
                            {log.firstName} {log.lastName}
                          </p>
                          <p>{log.eventName}</p>
                          <p>{new Date(log.logTimestamp).toLocaleString()}</p>
                        </div>
                      ))
                    : "No logs here"}
                </div>
              </div>
            </div>
          )}
          <div label="results" title="Results" tabClassName="tabStyle">
            <div className="tabContentContainer">
              <table>
                <tbody>{switchResults()}</tbody>
              </table>
            </div>
          </div>
        </Tabs>
      </div>
      <div className="csAndResolveContainer">
        {admin &&
          (csCompanies.includes(visit.company) ||
            csApiList.includes(visit.company) ||
            /^bask/.test(visit.company) ||
            /^foundation/.test(visit.company)) && (
            <div className="csContainer">
              {visit.isCSMessagePending ? (
                <div className="stickyWrapper">
                  <button onClick={openCSModal} className="visitButton">
                    Customer support response available
                  </button>
                  <div className="unreadCSmessages" />
                </div>
              ) : (
                <button onClick={openCSModal} className="visitButton">
                  Contact {visit.company} customer support
                </button>
              )}

              <Modal
                show={showCSModal}
                onHide={closeCSModal}
                dialogClassName="CSModal"
              >
                <div className="modalHeader">
                  <div className="modalTitle">
                    <span>Customer Support</span>
                    <button onClick={closeCSModal} className="modalCloseButton">
                      <img src={xIcon} className="xIcon" alt="close" />
                    </button>
                  </div>
                </div>
                <div className="modalBody">
                  <div className="scrollable">
                    {isCSLoading ? (
                      <p>Sending message...</p>
                    ) : (
                      customerServiceArray
                    )}
                  </div>
                  <button onClick={handleCSMarkRead} className="visitButton">
                    Mark as Read
                  </button>
                  <textarea
                    rows={5}
                    placeholder="Enter support message here"
                    onChange={handleCSChange}
                    value={CSMessage.content}
                  />
                </div>
                <div className="modalFooter">
                  <button onClick={closeCSModal} className="visitButton">
                    Cancel
                  </button>
                  <button
                    onClick={handleCSSubmit}
                    className="visitButton"
                    disabled={isCSLoading || !CSMessage.content}
                  >
                    Send
                  </button>
                </div>
              </Modal>
            </div>
          )}
        {admin && ecommerceCompanies.includes(visit.company) && (
          <div className="csContainer">
            {isChatPending ? (
              <div className="stickyWrapper">
                <button onClick={openChatModal} className="visitButton">
                  Admin response available
                </button>
                <div className="unreadCSmessages" />
              </div>
            ) : (
              <button onClick={openChatModal} className="visitButton">
                Contact {visit.company} admin
              </button>
            )}
          </div>
        )}
        {isDaybreakApneaVideo(visit) && (
          <button onClick={handleVisitNoShow} className="m-1 chatButton">
            No Show
          </button>
        )}
        {((visit.company === "jase" && visit.formObj.state === "MS") ||
          ["ubacareChronicMedSync", "apneaVideo", "patientManagement"].includes(
            visit.visitType
          )) &&
          !isVideoDone &&
          visit.photosApproved.approved && (
            <button onClick={handleVideoDoneClick} className="m-1 chatButton">
              Synchronous visit completed
            </button>
          )}
        {visit.status !== "canceled" &&
          (visit.photosApproved.approved || isAdminResolve) && (
            <ResolveContainer
              user={user}
              admin={admin}
              patient={props.patient}
              updatePatient={updatePatient}
              setCurrentVisit={setCurrentVisit}
              prescriptionSent={prescriptionSent}
              setPrescriptionSent={setPrescriptionSent}
              visit={visit}
              note={note}
              handleNotesChange={handleNotesChange}
              changeTab={changeTab}
              // statePharmNotes={statePharmNotes}
              // statePharmNotesLength={statePharmNotes.length}
              setVisitResolutionText={setVisitResolutionText}
              // setCanResolve={setCanResolve}
              updateVisit={updateVisit}
              isAdminResolve={isAdminResolve}
              setIsAdminResolve={setIsAdminResolve}
              // labCompanies={labCompanies}
              hioOutcome={hioOutcome}
              handleResolveHio={handleResolveHio}
              handleHioNoteChange={handleHioNoteChange}
              handleHioOutcomeChange={handleHioOutcomeChange}
              hioResolve={hioResolve}
              setHioResolve={setHioResolve}
              idPhotos={idPhotos}
              visitInternalChat={visitInternalChat}
              isPatientLoading={isPatientLoading}
              setIsPatientLoading={setIsPatientLoading}
              switchVideoDone={switchVideoDone}
            />
          )}
      </div>

      <Modal
        show={showRejectModal}
        onHide={closeRejectModal}
        dialogClassName="internalChatModal"
      >
        <div className="modalHeader">
          <div className="modalTitle">
            <span>Reject Photo</span>
            <button onClick={closeRejectModal} className="modalCloseButton">
              <img src={xIcon} className="xIcon" alt="close" />
            </button>
          </div>
        </div>
        <div className="modalBody">
          Are you sure you want to reject this photo?
          {rejectError && <div className="internalError">{rejectError}</div>}
        </div>
        <div className="modalFooter">
          <button onClick={closeRejectModal} className="visitButton">
            No
          </button>
          <button onClick={handleReject} className="visitButton">
            Yes, reject
          </button>
        </div>
      </Modal>

      {admin && ecommerceCompanies.includes(visit.company) && (
        <Modal
          show={showChatModal}
          onHide={handleCloseChat}
          dialogClassName="internalChatModal"
        >
          <div className="modalHeader">
            <div className="modalTitle">
              <span>Customer Support Chat</span>
              <button onClick={handleCloseChat} className="modalCloseButton">
                <img src={xIcon} className="xIcon" alt="close" />
              </button>
            </div>
          </div>
          <div className="modalBody">
            <div className="scrollable">
              <ul className="chatList">
                {submitting ? <li>Sending...</li> : orderChats}
              </ul>
            </div>
            <span className="internalError">{internalError}</span>
            <textarea
              rows={5}
              placeholder="Enter chat message here"
              onChange={handleMessageChange}
              value={internalMessage.content}
            />
          </div>
          <div className="modalFooter">
            <button onClick={handleCloseChat} className="patientButton">
              Cancel
            </button>
            <button onClick={handleSendInternalChat} className="patientButton">
              OK
            </button>
          </div>
        </Modal>
      )}

      <Modal
        show={showPdfModal}
        onHide={handleClosePdf}
        dialogClassName="dsModal"
      >
        <div className="modalHeader">
          <div className="modalTitle">
            <span>PDF Rx</span>
            <button onClick={handleClosePdf} className="modalCloseButton">
              <img src={xIcon} className="xIcon" alt="close" />
            </button>
          </div>
        </div>
        <div className="modalBody pdfModal">
          <Document file={currentImage} options={options} rotate={rotatePdf}>
            <Page
              scale={2}
              pageNumber={pageNumber}
              loading={"Loading PDF..."}
            />
          </Document>
          <p>
            Page {pageNumber} of {numPages}
          </p>
        </div>
        <div className="modalFooter">
          <button onClick={handleRotate} className="patientButton">
            Rotate
          </button>
          {numPages > 1 && (
            <>
              <button onClick={previousPdfPage} className="patientButton">
                Previous Page
              </button>

              <button onClick={nextPdfPage} className="patientButton">
                Next Page
              </button>
            </>
          )}
          <button onClick={handleClosePdf} className="patientButton">
            Close
          </button>
        </div>
      </Modal>

      {admin && (
        <Modal
          show={showPhotoUploadModal}
          onHide={handleClosePhotoUploadModal}
          dialogClassName="dsModal"
        >
          <div className="modalHeader">
            <div className="modalTitle">
              <span>Photos to upload</span>
              <button
                onClick={handleClosePhotoUploadModal}
                className="modalCloseButton"
              >
                <img src={xIcon} className="xIcon" alt="close" />
              </button>
            </div>
          </div>
          <div className="modalBody pdfModal">
            <div className="uploadLogo"></div>
            <label htmlFor="fileUpload" className="visitButton fileUpload">
              Choose Photos
            </label>
            <input
              type="file"
              accept="image/*"
              id="fileUpload"
              name="photo"
              onChange={handlePhoto}
              className="displayNone"
            />

            {!!photosToUpload.length && (
              <div className="photosToUploadContainer">
                {photosToUpload.map((photo) => {
                  return (
                    <div className="photosToUpload">
                      <img
                        className="uploadedImg"
                        alt={photo.filename}
                        src={photo.fullSrc}
                      />
                      <div className="fileDescriptionText">
                        {photo.filename}
                      </div>
                      <button
                        onClick={handleRemovePhoto}
                        name={photo.filename}
                        className="visitButton"
                      >
                        Remove
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="modalFooter">
            <button onClick={handleUploadPhotos} className="patientButton">
              Upload
            </button>
            <button
              onClick={handleClosePhotoUploadModal}
              className="patientButton"
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}

      {visit.company === "jase" && (
        <Modal
          show={imageVisible}
          onHide={closePhotoViewer}
          dialogClassName="photoModal"
        >
          <div className="modalHeader">
            <div className="modalTitle">
              <span>Photo with patient preference</span>
              <button onClick={closePhotoViewer} className="modalCloseButton">
                <img src={xIcon} className="xIcon" alt="close" />
              </button>
            </div>
          </div>
          <div className="modalBody">
            <div className="jaseModalBody">
              <div className="jaseTable">
                <table>
                  <tbody>{jasePatientPreference}</tbody>
                </table>
              </div>

              <div className="photoViewerContainer">
                <ReactPanZoom
                  image={currentImage}
                  alt="Image alt text"
                  height="200px"
                  width="200px"
                  className="photoViewer"
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {visit.company !== "jase" && (
        <Viewer
          visible={imageVisible}
          onClose={closePhotoViewer}
          onMaskClick={closePhotoViewer}
          images={[{ src: currentImage, alt: "patient pic" }]}
          scalable={false}
          changeable={false}
          disableMouseZoom={true}
          showTotal={false}
          noImgDetails={true}
          attribute={false}
          noNavbar={true}
          zoomSpeed={0.3}
        />
      )}
    </div>
  );
};

export default Visit;
