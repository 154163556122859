import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";

import "./PrescriptionSelect.scss";

import { routerMap } from "../../utils/router";
import { PrescriptionFilterTypesMap } from "./utils/prescriptionTypeUtils";

/** sample search response
 * [
       {
            "Name": "Baclofen (intrathecal - solution)",
            "Strength": [
                {
                    "Strength": "0.05 mg/mL",
                    "NDC": "25021068171"
                },
                {
                    "Strength": "0.5 mg/mL",
                    "NDC": "67457056220"
                },
                {
                    "Strength": "1 mg/mL",
                    "NDC": "67457056320"
                },
                {
                    "Strength": "2 mg/mL",
                    "NDC": "67457056420"
                }
            ]
        },
        {
            "Name": "Baclofen (oral - liquid)",
            "Strength": [
                {
                    "Strength": "5 mg/5 mL",
                    "NDC": "68134040116"
                }
            ]
        },
        ]
*/

const searchWithCallback = debounce(
  (token, searchTerm, prescriptionType, callback) => {
    routerMap
      .searchMedications(token, searchTerm, prescriptionType)
      .then(callback);
  },
  500
);

const getSearchResult = async (token, searchTerm, prescriptionType) => {
  const result = await new Promise((resolve) => {
    searchWithCallback(token, searchTerm, prescriptionType, resolve);
  });

  if (!result) {
    return [];
  }
  const listOfMeds = result.map((med) => {
    return {
      label: med.Name,
      value: med.Name,
      med: med,
      directions: "",
      pharmacyNotes: "",
      allowSubstitution: true,
    };
  });

  return listOfMeds;
};

const placeholderText = {
  [PrescriptionFilterTypesMap.medicine.key]: "Search Medication",
  [PrescriptionFilterTypesMap.compound.key]: "Search Medication",
  [PrescriptionFilterTypesMap.supply.key]: "Search Supply",
};

const PrescriptionSearch = (props) => {
  const { prescriptionType, currentMedicine, onMedicineSelected, token } =
    props;
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentListOfMeds, setCurrentListOfMeds] = useState([]);
  const addMedRef = useRef();

  const searchMedications = async (searchTerm) => {
    if (searchTerm && searchTerm.length >= 3) {
      const listOfMeds = await getSearchResult(
        token,
        searchTerm,
        prescriptionType
      );
      setCurrentListOfMeds(listOfMeds);
      return listOfMeds;
    }
    return [];
  };

  useEffect(() => {
    if (!currentMedicine?.med) {
      setCurrentListOfMeds([]);
      setSelectedOption(null);
    }
  }, [currentMedicine]);

  // this is for add after search
  const addMedicine = (med, actionMeta) => {
    if (med) {
      onMedicineSelected(med);
    }
    setSelectedOption(med);
    // clear result after select
    if (actionMeta.action !== "clear") {
      addMedRef.current.clearValue();
    }
  };

  const placeholderDisplay =
    (props.selectedMedDisplay && currentMedicine?.label) ||
    placeholderText[prescriptionType] ||
    `Search ${prescriptionType}`;
  // : placeholderText[prescriptionType] || `Search ${prescriptionType}`;

  return (
    <>
      <div className="search-add-medicine-bar-container">
        <AsyncSelect
          ref={addMedRef}
          value={selectedOption}
          isSearchable={true}
          loadingMessage={() => "Searching..."}
          placeholder={placeholderDisplay}
          onChange={addMedicine}
          defaultOptions={currentListOfMeds}
          loadOptions={searchMedications}
          classNamePrefix="search-med"
          classNames={{ menu: () => "search-result" }}
          menuPosition="fixed"
          cacheOptions
        />
      </div>
    </>
  );
};

PrescriptionSearch.propTypes = {
  /* med/compound/supply - see prescriptionTypeUtils */
  prescriptionType: PropTypes.string.isRequired,
  currentMedicine: PropTypes.object,
  onMedicineSelected: PropTypes.func.isRequired,
};

export default PrescriptionSearch;
