import React, { useState, useEffect } from "react";
import classNames from "classnames";

import "../MyAccount/MyAccount.css";
import { generateRandomClientName } from "../Favorites/tabs/utils";
import { routerMap } from "../utils/router";
import PlatformCompanySelect from "./PlatformCompanySelect";

const NewCompanyForm = (props) => {
  const { companies, curEditingCompany, closeModal, setCompanies, editMode } =
    props;
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState("");
  const [rxTexts, setRxTexts] = useState([]);

  const token = localStorage.getItem("jwt");

  const { createCompany, updateCompany, getRxTexts, getCompanies } = routerMap;

  const platformCompanies = [
    "bask",
    "foundation",
    "architek",
    "dosable",
    "carePortals",
  ];

  const checkEditableApiKey = () => {
    let name = curEditingCompany?.name;

    if (editMode && name) {
      return !platformCompanies.some((el) => name.includes(el));
    } else {
      return false;
    }
  };

  const editableApiKey = checkEditableApiKey();

  useEffect(() => {
    let newApiKey = editMode ? "" : generateRandomClientName(20);
    let chatMethod = curEditingCompany?.visitTypes.some((v) => v.phone)
      ? "SMS"
      : "API";
    let csMethod = "email";
    let email = curEditingCompany?.customerServiceEmail || "";
    let companyName = curEditingCompany?.name || "";
    let acceptableVisitTypes = curEditingCompany
      ? curEditingCompany.visitTypes
      : [];
    setFormData({
      ...formData,
      companyName: companyName,
      isPlatformSubCompany: false,
      email: email,
      apiKey: newApiKey,
      chatMethod: chatMethod,
      csMethod,
      acceptableVisitTypes: acceptableVisitTypes,
      country: "US",
    });
  }, []);

  const generateNewApiKey = (name) => {
    let newApiKey = generateRandomClientName(20);
    setFormData({ ...formData, [name]: newApiKey });
  };

  const injectedText = (visitType, displayName) => {
    const foundRxText = rxTexts.filter(
      (rxText) => rxText.visitType === visitType
    )[0];
    let injected =
      foundRxText?.text.replace("{companyName}", displayName) || "";
    if (formData.chatMethod !== "API") {
      injected = injected.replace("{medInfo}", "{break}{medInfo}");
    }
    return injected;
  };

  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber || phoneNumber === "+1") {
      return "";
    }
    // Remove all non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, "");
    // Ensure it starts with '1' and has only 11 digits
    let formatted = cleaned.startsWith("1") ? cleaned : "1" + cleaned;
    // Trim to 11 digits
    formatted = formatted.slice(0, 11);
    return `+${formatted}`;
  }

  const handleChange = (e, data) => {
    const { value, name } = e.target;

    if (name === "isPlatformSubCompany") {
      setFormData((prevFormData) => {
        let isPlatformSubCompany = data.isPlatformSubCompany;
        let newFormData;

        if (!isPlatformSubCompany) {
          // remove platform company
          const { platformCompanyName, ...rest } = prevFormData;
          newFormData = rest;
        } else {
          newFormData = prevFormData;
        }
        return {
          ...newFormData,
          [name]: isPlatformSubCompany,
        };
      });
    } else if (name === "phone") {
      let formattedPhoneNumber = formatPhoneNumber(value);
      setFormData({
        ...formData,
        [name]: formattedPhoneNumber,
      });
    } else if (name === "displayName") {
      if (formData.acceptableVisitTypes) {
        const newTypes = formData.acceptableVisitTypes.map((visitType) => {
          return {
            ...visitType,
            rxText: injectedText(visitType.type, value),
          };
        });
        setFormData({
          ...formData,
          acceptableVisitTypes: newTypes,
          [name]: value,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const checkIfVisitTypeSelected = (value) => {
    if (formData.acceptableVisitTypes) {
      return formData.acceptableVisitTypes.some((obj) => obj.type === value);
    } else {
      return false;
    }
  };

  const getIndexOfVisitType = (value) => {
    if (formData.acceptableVisitTypes) {
      return formData.acceptableVisitTypes.findIndex(
        (obj) => obj.type === value
      );
    }
  };

  const handleVisitTypeChange = (e) => {
    const { value, name, type, checked } = e.target;
    let ogVisitTypes = formData.acceptableVisitTypes
      ? [...formData.acceptableVisitTypes]
      : [];
    let objAdded = checkIfVisitTypeSelected(name);
    let newVisitTypes = [...ogVisitTypes];

    //add or remove visit type from array based on checkbox state
    if (type === "checkbox") {
      if (!checked) {
        newVisitTypes = ogVisitTypes.filter((obj) => obj.type !== value);
      } else {
        newVisitTypes.push({
          type: value,
          phone: "",
          rxText: injectedText(value, formData["displayName"]),
        });
      }
    }

    //update phone number of visit type
    if (type === "text" && objAdded) {
      let index = getIndexOfVisitType(name);
      if (index >= 0 && index < ogVisitTypes.length) {
        newVisitTypes[index] = { ...ogVisitTypes[index] };
      }
      let newNumber = formatPhoneNumber(value);
      newVisitTypes[index].phone = newNumber;
    }

    //update rxText of visit type
    if (type === "textarea" && objAdded) {
      let index = getIndexOfVisitType(name);
      if (index >= 0 && index < ogVisitTypes.length) {
        newVisitTypes[index] = { ...ogVisitTypes[index] };
      }
      newVisitTypes[index].rxText = value;
    }

    //update formData with new visit types
    setFormData({
      ...formData,
      acceptableVisitTypes: newVisitTypes,
    });
  };

  // const handleOtherVisitTypeChange = (e) => {
  //   const { value, name, checked } = e.target;

  //   let updatedOtherAcceptableVisitType =
  //     formData.otherAcceptableVisitType || {};
  //   if (name === "otherAcceptableVisitTypeCheckbox") {
  //     setFormData({
  //       ...formData,
  //       otherChecked: checked,
  //     });
  //   } else if (name === "otherAcceptableVisitTypeName") {
  //     updatedOtherAcceptableVisitType["type"] = value;
  //     setFormData({
  //       ...formData,
  //       otherAcceptableVisitType: updatedOtherAcceptableVisitType,
  //     });
  //   } else if (name === "otherAcceptableVisitTypePhone") {
  //     updatedOtherAcceptableVisitType["phone"] = formatPhoneNumber(value);
  //     setFormData({
  //       ...formData,
  //       otherAcceptableVisitType: updatedOtherAcceptableVisitType,
  //     });
  //   }
  // };

  const closeParentModal = () => {
    closeModal();
  };

  const otherVisitTypeCheck = () => {
    if (formData["otherChecked"]) {
      return (
        formData["otherAcceptableVisitType"] &&
        formData["otherAcceptableVisitType"].type !== undefined &&
        formData["otherAcceptableVisitType"].type !== null &&
        formData["otherAcceptableVisitType"].type !== ""
      );
    }
    return true;
  };

  const getSelectedVisitTypeObj = () => {
    let otherOptionObj = formData["otherChecked"]
      ? formData["otherAcceptableVisitType"]
      : null;

    let tempVisitTypes = formData["acceptableVisitTypes"]
      ? formData["acceptableVisitTypes"]
      : [];

    return otherOptionObj
      ? [...tempVisitTypes, otherOptionObj]
      : tempVisitTypes;
  };

  const visitTypesCheck = () => {
    let smsEnabled = formData["chatMethod"] && formData["chatMethod"] === "SMS";

    let selectedVisitTypes = getSelectedVisitTypeObj();

    let hasAtLeastOneVisitType =
      selectedVisitTypes && selectedVisitTypes.length > 0;

    let allPhoneEntered =
      hasAtLeastOneVisitType &&
      selectedVisitTypes.every(
        (obj) =>
          obj.phone !== undefined && obj.phone !== null && obj.phone !== ""
      );

    if (smsEnabled) {
      return allPhoneEntered;
    } else {
      return hasAtLeastOneVisitType;
    }
  };

  const isReadyToSubmit = editMode
    ? visitTypesCheck()
    : formData.companyName &&
      formData.displayName &&
      visitTypesCheck() &&
      otherVisitTypeCheck() &&
      formData.apiKey;

  const removePhoneFromAllVisitTypes = (selectedVisitTypes) => {
    let updatedVisitTypes = selectedVisitTypes.map((obj) => {
      const { phone, ...rest } = obj;
      return rest;
    });
    return updatedVisitTypes;
  };

  const updateFormData = () => {
    let selectedVisitTypes = getSelectedVisitTypeObj();
    let updatedFormData =
      formData["chatMethod"] === "API"
        ? removePhoneFromAllVisitTypes(selectedVisitTypes)
        : selectedVisitTypes;
    const hasChatApi = formData["chatMethod"] === "API" ? JSON.parse(true) : JSON.parse(false);
    const hasCSApi = formData["csMethod"] === "API" ? JSON.parse(true) : JSON.parse(false);
    let companyName = formData["companyName"];
    if (formData.isPlatformSubCompany) {
      companyName = formData["platformCompanyName"] + companyName;
    }
    return {
      ...formData,
      acceptableVisitTypes: updatedFormData,
      hasChatApi,
      hasCSApi,
      companyName,
    };
  };

  const existingCompanyNames = new Set(companies.map((c) => c.name));
  function isPlatformNameIncluded(platformCompanyName, name) {
    return platformCompanyName && name && name.startsWith(platformCompanyName);
  }

  const handleCompanySubmit = async () => {
    let updatedFormData = updateFormData();

    if (editMode) {
      try {
        if (updatedFormData.companyName !== curEditingCompany.name) {
          if (existingCompanyNames.has(updatedFormData.companyName)) {
            setMessage(
              `Company with this name already exists: ${updatedFormData.companyName}`
            );
            return;
          }
        }
        setMessage("");
        await updateCompany(token, curEditingCompany, updatedFormData);
        await getCompanies(token, setCompanies);
        closeParentModal();
      } catch (error) {
        setMessage(error.message);
      }
    } else {
      if (existingCompanyNames.has(updatedFormData.companyName)) {
        setMessage(
          `Company with this name already exists: ${updatedFormData.companyName}`
        );
        return;
      }
      if (
        !isPlatformNameIncluded(
          updatedFormData.platformCompanyName,
          updatedFormData.companyName
        )
      ) {
        setMessage("Please make sure platform company name is selected.");
      }
      try {
        setMessage("");
        await createCompany(token, updatedFormData);
        await getCompanies(token, setCompanies);
        closeParentModal();
      } catch (error) {
        setMessage(error.message);
      }
    }
  };

  const visitTypes = [
    ...new Set(
      companies
        .map((company) => company.visitTypes.map((visitType) => visitType.type))
        .flat()
    ),
  ].sort();

  const checkedVisitTypes = visitTypes.map((visitType, index) => {
    let typeChecked = checkIfVisitTypeSelected(visitType);
    let indexInForm = getIndexOfVisitType(visitType);
    let smsChecked = formData["chatMethod"]
      ? formData["chatMethod"] === "SMS"
      : false;
    return (
      <div className="checkGroup" key={visitType}>
        <input
          type="checkbox"
          value={visitType || ""}
          checked={typeChecked}
          name="acceptableVisitTypes"
          onChange={handleVisitTypeChange}
          className="checkBox"
        />
        <span>{visitType}</span>
        {typeChecked && smsChecked && (
          <div
            className="checkGroup"
            style={{ marginLeft: "20px", width: "80%" }}
          >
            <div className="formField">
              Visit Type Phone Number{" "}
              {!editMode && <span className="userFormError"> (Required)</span>}
            </div>
            <input
              name={visitType}
              placeholder="+1 000-000-0000"
              style={{ width: "100%" }}
              value={
                formData["acceptableVisitTypes"]
                  ? formData["acceptableVisitTypes"][indexInForm]["phone"]
                  : ""
              }
              onChange={handleVisitTypeChange}
              className="formInput"
            />
          </div>
        )}
      </div>
    );
  });

  const injectedVisitTypes = (visitType) => {
    const typeText =
      formData.chatMethod === "API"
        ? visitType.type
        : `${visitType.type} - Phone: ${visitType.phone}`;
    const injected =
      visitType.rxText || injectedText(visitType.type, formData["displayName"]);
    return (
      <div key={`visitType-message-${visitType.type}`}>
        <div>{typeText}</div>
        {editMode ? (
          <textarea
            name={visitType.type}
            value={injected || ""}
            className="messageTextArea"
            onChange={handleVisitTypeChange}
          />
        ) : (
          <textarea
            name="rxText"
            value={injected || ""}
            className="messageTextArea"
            disabled
            readOnly
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    const asyncGetTexts = async () => {
      await getRxTexts(token, setRxTexts);
    };
    asyncGetTexts();
  }, []);

  return (
    <>
      {message && message.length && (
        <span className="mx-4 my-2 userFormError">Create Error: {message}</span>
      )}
      <form className="formStyle">
        <div className="formRow">
          <div>
            <div className="formField">
              Company Name
              {!editMode && <span className="userFormError"> (Required)</span>}
            </div>
            <div className="withPrefixInput">
              {formData.platformCompanyName && (
                <span className="formPrefix prefixFormInput" role="textbox">
                  {formData.platformCompanyName || ""}
                </span>
              )}
              <input
                name="companyName"
                value={formData.companyName || ""}
                placeholder="Company Name"
                onChange={handleChange}
                className={classNames("prefixFormInput", {
                  hasPrefix: !!formData.platformCompanyName,
                })}
              />
            </div>
          </div>
          {!editMode && (
            <div>
              <div className="formField">
                Display Name{" "}
                {!editMode && (
                  <span className="userFormError"> (Required)</span>
                )}
              </div>
              <input
                name="displayName"
                placeholder="Display Name"
                onChange={handleChange}
                className="formInput"
              />
            </div>
          )}
        </div>
        <div className="formRow">
          <div>
            <div className="formField">Customer Service Email</div>
            <input
              name="email"
              value={formData.email || ""}
              placeholder="Customer Service Email"
              onChange={handleChange}
              className="formInput"
            />
          </div>
          {!editMode && (
            <div>
              <div className="formField">Country</div>
              <input
                name="country"
                placeholder="country"
                value="US"
                className="formInput"
                readOnly
                disabled
              />
            </div>
          )}
        </div>
        {!editMode && (
          <>
            <div className="formRow">
              <div>
                <div className="formField">Chat Method</div>
                <div className="chatMethodCheckboxHolder">
                  <div className="checkGroup" key="chatMethod-SMS">
                    <input
                      type="radio"
                      value="SMS"
                      name="chatMethod"
                      checked={formData["chatMethod"] === "SMS"}
                      onChange={handleChange}
                      className="checkBox"
                    />
                    <span>SMS</span>
                  </div>
                  <div className="checkGroup" key="chatMethod-API">
                    <input
                      type="radio"
                      value="API"
                      name="chatMethod"
                      checked={formData["chatMethod"] === "API"}
                      onChange={handleChange}
                      className="checkBox"
                    />
                    <span>API</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="formRow">
              <div>
                <div className="formField">Customer support method</div>
                <div className="chatMethodCheckboxHolder">
                  <div className="checkGroup" key="csMethod-email">
                    <input
                      type="radio"
                      value={"email"}
                      name="csMethod"
                      checked={formData["csMethod"] === "email"}
                      onChange={handleChange}
                      className="checkBox"
                    />
                    <span>Email</span>
                  </div>
                  <div className="checkGroup" key="csMethod-API">
                    <input
                      type="radio"
                      value={"API"}
                      name="csMethod"
                      checked={formData["csMethod"] === "API"}
                      onChange={handleChange}
                      className="checkBox"
                    />
                    <span>API</span>
                  </div>
                </div>
              </div>
            </div>
            <PlatformCompanySelect
              editMode={editMode}
              formData={formData}
              handleChange={handleChange}
            />
          </>
        )}
        <div className="formRowLeft">
          <div style={{ width: "100%" }}>
            <div className="formField">
              Visit Types{" "}
              {!editMode && <span className="userFormError"> (Required)</span>}
            </div>
            <div className="scrolling checkedStates">{checkedVisitTypes}</div>
            {/* <div className="otherCheckedBox" key="visitType-Other">
              <input
                type="checkbox"
                value="Other"
                name="otherAcceptableVisitTypeCheckbox"
                onChange={handleOtherVisitTypeChange}
                className="checkBox"
              />
              <span>Other</span>
              {formData["otherChecked"] && (
                <div className="formRow">
                  <div className="checkGroup">
                    <div className="formField">
                      Visit Type{" "}
                      <span className="userFormError"> (Required)</span>
                    </div>
                    <input
                      name="otherAcceptableVisitTypeName"
                      placeholder="Enter other visit type"
                      style={{ width: "100%" }}
                      value={
                        formData["otherAcceptableVisitType"]
                          ? formData["otherAcceptableVisitType"]["type"]
                            ? formData["otherAcceptableVisitType"]["type"]
                            : ""
                          : ""
                      }
                      className="formInput"
                      onChange={handleOtherVisitTypeChange}
                    />
                  </div>

                  {formData["chatMethod"] &&
                    formData["chatMethod"] === "SMS" && (
                      <div className="checkGroup">
                        <div className="formField">
                          Visit Type Phone Number{" "}
                          <span className="userFormError"> (Required)</span>
                        </div>
                        <input
                          name="otherAcceptableVisitTypePhone"
                          placeholder="+1 000-000-0000"
                          style={{ width: "100%" }}
                          value={
                            formData["otherAcceptableVisitType"]
                              ? formData["otherAcceptableVisitType"]["phone"]
                                ? formData["otherAcceptableVisitType"]["phone"]
                                : ""
                              : ""
                          }
                          onChange={handleOtherVisitTypeChange}
                          className="formInput"
                        />
                      </div>
                    )}
                </div>
              )}
            </div> */}
          </div>
        </div>
        <div className="formRow flex-column">
          <div className="formField">Visit Types Selected</div>
          <div className="">
            {formData?.acceptableVisitTypes?.map((visitType) => {
              return injectedVisitTypes(visitType);
            })}
          </div>
        </div>
        <div className="formRow">
          {!formData["isPlatformSubCompany"] && !editMode && (
            <div>
              <div className="formField">API Key</div>
              <input
                name="apiKey"
                placeholder="API Key"
                value={formData.apiKey}
                className="formInput"
                readOnly
                disabled
              />
            </div>
          )}
        </div>
        <div className="formRow">
          {editableApiKey && (
            <div>
              <div className="formField">API Key</div>
              <input
                name="apiKey"
                placeholder="●●●●●●●●●●●●●●●●●●●●"
                value={formData.apiKey}
                className="formInput"
                onChange={handleChange}
              />
              <div
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginTop: "5px",
                  fontSize: "14px",
                  width: "fit-content",
                }}
                onClick={() => generateNewApiKey("apiKey")}
              >
                Generate New API Key
              </div>
            </div>
          )}
          {editMode && (
            <div>
              <div className="formField">Dashboard API Key</div>
              <input
                name="apiKeyDashboard"
                placeholder="●●●●●●●●●●●●●●●●●●●●"
                value={formData.apiKeyDashboard}
                className="formInput"
                onChange={handleChange}
              />
              <div
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginTop: "5px",
                  fontSize: "14px",
                  width: "fit-content",
                }}
                onClick={() => generateNewApiKey("apiKeyDashboard")}
              >
                Generate New Dashboard API Key
              </div>
            </div>
          )}
        </div>
        <div className="formRowRight">
          <button
            type="button"
            className="formButton"
            disabled={!isReadyToSubmit}
            onClick={handleCompanySubmit}
          >
            {editMode ? "Update" : "Create"} Company
          </button>
        </div>
      </form>
    </>
  );
};

export default NewCompanyForm;
