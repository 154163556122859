import axios from "axios";

import config from "../config";

export const getCookie = (name) => {
  try {
    let cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    return cookieValue ? cookieValue.pop() : "";
  } catch (e) {
    console.log("Could not get cookie:", e);
  }
};

export const CookieName = {
  ACCESS_TOKEN: "beluga_jwt_token",
  REFRESH_TOKEN: "beluga_jwt_refresh",
};

export const getAxiosInstance = () =>
  axios.create({
    baseURL: config.backend.URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

// // /*global google*/
// import Geocode from 'react-geocode';
// import PharmacyList from '../PharmacyList';

// Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

// var infowindow = new window.google.maps.InfoWindow;
// let marker, i;
// let markerArray = [];

// const pharmCoords = PharmacyList.map(async (pharmacy, index) =>{
//   const response = await Geocode.fromAddress(pharmacy.address);
//   return {
//     ...pharmacy,
//     coords: response.results[0].geometry.location
//   };
// })

// for (i = 0; i < pharmCoords.length; i++) {
//   marker = new window.google.maps.Marker({
//         position: pharmCoords[i].coords,
//         map: map
//   });

//   window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
//         return function() {
//             infowindow.setContent(pharmCoords[i].title);
//             infowindow.open(map, marker);
//         }
//   })(marker, i));
//   markerArray.push(marker);
// }

// export default markerArray;
