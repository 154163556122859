import React, { useEffect, useRef, useState } from "react";

import sendIcon from "../Assets/Icon/send@2x.png";
import { routerMap } from "../utils/router";

import "./MessagingContainer.css";

const MessagingContainer = (props) => {
  const {
    chat,
    phoneNumber,
    handleSendText,
    error,
    visitResolutionText,
    // rejectText,
    visit,
    updateVisit,
    canResolve,
    // isRejecting,
    gettingChats,
    updateDocTime,
    isHourly,
  } = props;
  const [message, setMessage] = useState({
    to: phoneNumber,
    body: canResolve ? visitResolutionText : "",
  });
  const [textClass, setTextClass] = useState("messageTextArea");
  const [submitting, setSubmitting] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const elementRef = useRef();
  const { chatList } = routerMap;

  const isChat = () => {
    switch (true) {
      case /^bask/.test(visit.company):
      case chatList.includes(visit.company):
      case visit.company === "hio" &&
        (visit.visitType === "acr" || visit.visitType === "postResultAcr"):
        return true;
      default:
        return false;
    }
  };

  const setErrors = (errorMessage) => {
    setHasErrors(true);
    setTextClass("messageTextArea charCountOver");
    setErrorMessage(errorMessage);
    return;
  };

  const removeErrors = () => {
    setHasErrors(false);
    setTextClass("messageTextArea");
    setErrorMessage("");
    return;
  };

  const handleChange = (e) => {
    setMessage({
      ...message,
      body: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (message.body.length) {
      setSubmitting(true);
      await handleSendText(message);
      setSubmitting(false);
      setMessage({
        ...message,
        body: "",
      });
    }
  };

  const handleMarkAsRead = async () => {
    let data;
    if (visit.resolvedTimestamp) {
      data = {
        visitUpdate: {
          status: "resolved",
          currentDoc: null,
          updateTimestamp: new Date(),
        },
      };
    } else {
      data = {
        visitUpdate: {
          status: "pending",
          updateTimestamp: new Date(),
        },
      };
    }
    if (isHourly) {
      await updateDocTime();
    }
    await updateVisit(visit._id, data);
  };

  const handleShowResolveText = () => {
    setMessage({
      ...message,
      body: visit.resolveText,
    });
  };
  // render time sent in local browser time
  const timeAt = (time) => {
    const then = new Date(time);
    let hours = then.getHours();
    let ampm = "am";
    if (hours >= 12) {
      if (hours === 24 || hours === 12) {
        hours = 12;
      } else {
        hours = hours % 12;
      }
      ampm = "pm";
    }
    let minutes = then.getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    const month = then.getMonth() + 1;
    const date = then.getDate();
    const middleDot = "\u00B7";
    const prettyThen = `${month}/${date} ${middleDot} ${hours}:${minutes}${ampm}`;
    return prettyThen;
  };

  const sortedChat = chat
    .map((chatObj) => {
      return chatObj.messages
        .filter((message) => message !== null)
        .map((message) => {
          return {
            message: message,
            visitType: chatObj.visitType,
            company: chatObj.company,
          };
        });
    })
    .flat()
    .sort(
      (a, b) => new Date(a.message.createdAt) - new Date(b.message.createdAt)
    );

  const chatBubbleClass = (senderType, destination) => `
chatBubbleMessageContainer
${senderType}Style
${!!destination && `${destination}DestinationStyle`}
`;

  const chatArray =
    chat && chat.length > 0 ? (
      sortedChat
        .map(
          ({
            message: {
              senderType,
              senderName,
              _id,
              content,
              createdAt,
              destination,
              messageType,
            },
            visitType,
            company,
          }) => {
            return (
              <div className={`${senderType}MessageContainer`} key={_id}>
                <div className="senderName">
                  {senderType === "doctor" && "Dr."} {senderName}{" "}
                  {messageType && `(${messageType})`}
                </div>
                <li className={chatBubbleClass(senderType, destination)}>
                  {content ===
                  "## Photo in s3 bucket ##" ? null : content.slice(0, 4) ===
                    "http" ? (
                    <a href={content} target="_blank" rel="noreferrer">
                      {content}
                    </a>
                  ) : (
                    content
                  )}
                  <div className="chatMetadata">
                    <div className="smallChat">Visit Type: {visitType}</div>
                    <div className="smallChat">Company: {company}</div>
                  </div>
                </li>
                <div className={`${senderType}SenderInfo`}>
                  {timeAt(createdAt)}
                </div>
              </div>
            );
          }
        )
        .filter((item) => item)
    ) : (
      <li>Nothing in the chat</li>
    );
  const lastMessageClass =
    chat && chat.length > 0 && chatArray[chatArray.length - 1].props.className;

  useEffect(() => {
    elementRef.current.scrollIntoView();
    setMessage({
      ...message,
      body: canResolve ? visitResolutionText : "",
      // body: canResolve ? visitResolutionText : isRejecting ? rejectText : '',
    });
  }, []);

  useEffect(() => {
    // force replace referral reason
    if (message.body.includes("{REFERRAL_REASON}")) {
      setErrors("Please enter a valid referral reason");
      return;
    } else {
      removeErrors();
    }
    // if SMS company and visitType, enforce length restriction
    if (!isChat()) {
      if (message.body.includes("{break}")) {
        const arrayed = message.body.split("{break}");
        if (arrayed[0].length >= 1600 || arrayed[1].length >= 1600) {
          setErrors("Maximum message length reached");
        } else {
          removeErrors();
        }
      } else if (message.body.length >= 1600) {
        setErrors("Maximum message length reached");
      } else {
        removeErrors();
      }
    }
  }, [message]);

  return (
    <div className="scrollContainer">
      <div className="scrollableMessages">
        <ul>
          {!submitting && !error && !gettingChats && chatArray}
          {gettingChats && <li>Getting chats...</li>}
          {submitting && <li>Sending...</li>}
          {error && <li>There was an error sending your message!</li>}
        </ul>
        {lastMessageClass === "patientMessageContainer" && (
          <button onClick={handleMarkAsRead} className="visitButton">
            Mark as Read
          </button>
        )}
        {visit.resolvedTimestamp && (
          <button onClick={handleShowResolveText} className="visitButton">
            Show resolution text
          </button>
        )}
        <div ref={elementRef} />
      </div>
      {errorMessage && <div className="errorText">{errorMessage}</div>}
      <div className="messageSendContainer">
        <textarea
          rows={3}
          className={textClass}
          onChange={handleChange}
          value={message.body}
        />
        <button
          onClick={handleSubmit}
          className="sendButton"
          disabled={submitting || hasErrors || !message.body.length}
        >
          <img src={sendIcon} alt="send" />
          <div>{canResolve ? "Send message and resolve" : "Send Message"}</div>
        </button>
      </div>
    </div>
  );
};

export default MessagingContainer;
