import { rest } from "msw";
import config from "../config";
import * as rxNewData from "./data/dosespot/rxNewData";

const stagingPath = config.backend.URL;
// http://localhost:9000/dosespot/medication?name=amox
const handlers = [
  rest.get(`${stagingPath}/dosespot/medication`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(300),
      ctx.json(rxNewData.successfullSearch)
    );
  }),
  rest.post(`${stagingPath}/dosespot/medication`, async (req, res, ctx) => {
    console.log("received", await req.json());
    return res(
      ctx.status(200),
      ctx.delay(300),
      ctx.json(rxNewData.susccessfulNameAndStrengthLookUp)
    );
  }),
  rest.post(`${stagingPath}/dosespot/prescription/add`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      // note: update delay to test the Toast on long api call
      ctx.delay(15 * 1000),
      ctx.json(rxNewData.successfulAdd)
    );
  }),
  rest.post(`${stagingPath}/dosespot/prescription/send`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      // note: update delay to test the Toast on long api call
      ctx.delay(15 * 1000),
      ctx.json(rxNewData.successfulSend)
    );
  }),
];

export default handlers;
