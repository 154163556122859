import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { routerMap } from "../../utils/router";

import "./PrescriptionSelect.scss";
import "./PrescriptionForm.scss";
import NumberInput from "../NumberInput";
import xIcon from "../../Assets/Icon/x@2x.png";
import {
  PrescriptionFilterTypesMap,
  getDispenseUnitOptions,
} from "./utils/prescriptionTypeUtils";

const dispenseUnitOptions = getDispenseUnitOptions();

const PrescriptionForm = (props) => {
  const {
    prescriptionType,
    currentMedicine,
    setCurrentMedicine,
    savePrescription,
    isSaving,
    buttonName,
    noButton,
    allowAddNewCompound,
    setAllowAddNewCompound,
  } = props;

  const token = localStorage.getItem("jwt");

  const curRefills = currentMedicine?.refills || "";
  const curQuantity = currentMedicine?.quantity || "";
  const curDaysSupply = currentMedicine?.daysSupply || "";
  const curDirections = currentMedicine?.directions || "";
  const curPharmacyNotes = currentMedicine?.pharmacyNotes || "";
  const curAllowSubState = currentMedicine?.allowSubstitution || false;
  const curDispenseUnitName = currentMedicine?.dispenseUnitName;
  // mainly for compound
  const curMedName = currentMedicine?.name;

  const [curUnit, setCurUnit] = useState(
    currentMedicine?.DispenseUnitDescription || ""
  );

  const curStrength = currentMedicine?.strength
    ? {
        value: currentMedicine?.strength,
        label: currentMedicine?.strength,
        NDC: currentMedicine?.NDC,
      }
    : null;

  let enableSaveButton =
    currentMedicine?.refills &&
    currentMedicine?.quantity &&
    currentMedicine?.directions;

  if (prescriptionType === PrescriptionFilterTypesMap.supply.key) {
    enableSaveButton = !!(
      enableSaveButton &&
      currentMedicine?.med.SupplyId &&
      currentMedicine?.dispenseUnitName
    );
  } else if (prescriptionType === PrescriptionFilterTypesMap.medicine.key) {
    enableSaveButton =
      enableSaveButton &&
      currentMedicine?.strength &&
      currentMedicine?.NDC &&
      true;
  } else if (prescriptionType === PrescriptionFilterTypesMap.compound.key) {
    enableSaveButton =
      enableSaveButton &&
      currentMedicine?.dispenseUnitName &&
      currentMedicine?.name &&
      true;
  }

  const getMedicationDetails = async () => {
    try {
      const res = await routerMap.getMedicationDetails(
        token,
        currentMedicine.label,
        currentMedicine.strength
      );
      setCurrentMedicine((prevState) => {
        return {
          ...prevState,
          unit: res?.DispenseUnitName,
        };
      });
      setCurUnit(res?.DispenseUnitName);
    } catch (err) {}
  };

  // only for Med type
  useEffect(() => {
    if (
      currentMedicine?.strength &&
      !currentMedicine?.DispenseUnitDescription
    ) {
      getMedicationDetails();
    }
  }, [currentMedicine?.strength]);

  const addCurrentStrength = (strength) => {
    setCurrentMedicine((prevState) => {
      const suggestedName =
        prescriptionType === PrescriptionFilterTypesMap.compound.key
          ? `${currentMedicine?.med?.Name} ${strength.value}`
          : prevState.name;
      return {
        ...prevState,
        strength: strength.value,
        NDC: strength.NDC,
        name: suggestedName,
      };
    });
  };

  const addDispenseUnitName = (name) => {
    setCurrentMedicine((prevState) => {
      return {
        ...prevState,
        dispenseUnitName: name,
      };
    });
  };

  // for after selecting strength/refills/days supply, quantity
  const confirmMedicine = async (e) => {
    savePrescription(e);
  };

  const handleNumberChange = (evt) => {
    switch (evt.name) {
      case "refills":
        setCurrentMedicine((prevState) => {
          return {
            ...prevState,
            refills: evt.value,
          };
        });

        break;
      case "quantity":
        setCurrentMedicine((prevState) => {
          return {
            ...prevState,
            quantity: evt.value,
          };
        });
        break;
      case "daysSupply":
        setCurrentMedicine((prevState) => {
          return {
            ...prevState,
            daysSupply: evt.value,
          };
        });
        break;
      default:
        break;
    }
  };

  const handleChange = (evt) => {
    switch (evt.target.name) {
      case "directions":
        setCurrentMedicine((prevState) => {
          return {
            ...prevState,
            directions: evt.target.value,
          };
        });
        break;
      case "pharmacyNotes":
        setCurrentMedicine((prevState) => {
          return {
            ...prevState,
            pharmacyNotes: evt.target.value,
          };
        });
        break;
      case "allowSubstitution":
        setCurrentMedicine((prevState) => {
          return {
            ...prevState,
            allowSubstitution: evt.target.checked,
          };
        });
        break;
      case "ingredientName":
        setCurrentMedicine((prevState) => {
          return {
            ...prevState,
            name: evt.target.value,
          };
        });
        break;
      default:
        break;
    }
  };

  const getCurrentMedStrength = (med) => {
    return (med?.med?.Strength || currentMedicine?.med?.Strength || []).map(
      (strength) => {
        return {
          label: strength.Strength,
          value: strength.Strength,
          NDC: strength.NDC,
        };
      }
    );
  };

  const buttonSavingClassName = isSaving
    ? " loadingButton savingInProgress"
    : "";

  const confirmButtonName = buttonName ? buttonName : "Save Medicine";
  const isCompound =
    PrescriptionFilterTypesMap.compound.key === prescriptionType;
  const isSupply = PrescriptionFilterTypesMap.supply.key === prescriptionType;
  const shouldShowStrengthSelect =
    [
      PrescriptionFilterTypesMap.medicine.key,
      PrescriptionFilterTypesMap.compound.key,
    ].includes(prescriptionType) && currentMedicine?.med?.Name;

  if (!allowAddNewCompound && isCompound) {
    return (
      <button
        data-test-id="showNewCompoundForm"
        className={`visitButton addMedButton${buttonSavingClassName}`}
        onClick={() => {
          setAllowAddNewCompound(true);
        }}
      >
        Add New Compound
      </button>
    );
  }

  return (
    <>
      <div className="med-prescribe-section" style={props.style}>
        <div className="med-prescribe-input-holder">
          <label className="pb-2">
            {currentMedicine?.med?.Name || (isCompound && "Compound") || ""}
          </label>
          <button
            onClick={() => {
              setCurrentMedicine(null);
              if (isCompound) {
                setAllowAddNewCompound(false);
              }
            }}
            className="modalCloseButton"
          >
            <img src={xIcon} className="xIcon" alt="close" />
          </button>
        </div>
        {shouldShowStrengthSelect && (
          <div className="med-prescribe-input-holder">
            <label className="med-prescribe-section-label">Strength: </label>
            <div className="med-prescribe-dropdown-select-holder">
              <Select
                className="strength-select"
                name="strength"
                key="strength-select"
                classNamePrefix="med-selection"
                placeholder="Select"
                isSearchable={false}
                defaultValue={null}
                value={curStrength}
                onChange={addCurrentStrength}
                options={getCurrentMedStrength()}
              />
            </div>
          </div>
        )}

        {prescriptionType === PrescriptionFilterTypesMap.compound.key && (
          <div className="med-prescribe-input-holder">
            <label className="med-prescribe-section-label">
              Ingredient Name:
            </label>
            <textarea
              name="ingredientName"
              onChange={handleChange}
              placeholder="Ingredient name with strength"
              className="directions"
              type="text"
              value={curMedName}
            />
          </div>
        )}

        {/* {enableStrengthLabel ? (
          <div className='med-prescribe-input-holder'>
            <label className='med-prescribe-section-label'>Strength: </label>
            <Select
              key='strength-select'
              classNamePrefix='med-selection'
              placeholder='Select a Strength'
              isSearchable={false}
              defaultValue={null}
              value={curStrength}
              onChange={addCurrentStrength}
              options={getCurrentMedStrength()}
            />
          </div>
        ) : (
          <Select
            key='strength-select'
            classNamePrefix='med-selection'
            placeholder='Select a Strength'
            isSearchable={false}
            defaultValue={null}
            value={curStrength}
            onChange={addCurrentStrength}
            options={getCurrentMedStrength()}
          />
        )} */}
        {(isSupply || isCompound) && (
          <div className="med-prescribe-input-holder">
            <label className="med-prescribe-section-label">Unit: </label>
            <div className="med-prescribe-dropdown-select-holder">
              <Select
                className="dispenseUnitName-select"
                name="dispenseUnitName"
                key="dispenseUnitName-select"
                classNamePrefix="med-selection"
                placeholder="Select"
                isSearchable={false}
                defaultValue={null}
                value={curDispenseUnitName}
                onChange={addDispenseUnitName}
                options={dispenseUnitOptions}
              />
            </div>
          </div>
        )}
        {curUnit && (
          <div className="med-prescribe-input-holder">
            <label className="med-prescribe-section-label">Unit: </label>
            <input
              name="unit"
              placeholder={curUnit}
              className="unit"
              value={curUnit}
              disabled
            />
            {/* Below is unit selection for future implementation */}
            {/* <div className='med-prescribe-dropdown-select-holder'>
              {' '}
              <Select
                disabled={true}
                key='unit-select'
                classNamePrefix='med-selection'
                placeholder={curUnit}
                isSearchable={false}
                defaultValue={curUnit}
                // value={curStrength}
                // onChange={addCurrentStrength}
                // options={getCurrentMedStrength()}
              />
            </div> */}
          </div>
        )}

        <NumberInput
          containerClassName="med-prescribe-input-holder"
          validateConfig={{
            type: "float",
            min: 0,
          }}
          label="Quantity:"
          name="quantity"
          onChange={handleNumberChange}
          placeholder="Quantity"
          className="quantity"
          value={curQuantity}
        />
        <NumberInput
          containerClassName="med-prescribe-input-holder"
          validateConfig={{
            max: 12,
            min: 0,
          }}
          label="Refills:"
          name="refills"
          onChange={handleNumberChange}
          placeholder="Refills"
          className="refills"
          min="0"
          max="12"
          value={curRefills}
        />
        <NumberInput
          containerClassName="med-prescribe-input-holder"
          validateConfig={{
            max: 366,
            min: 0,
          }}
          label="Days Supply:"
          name="daysSupply"
          onChange={handleNumberChange}
          placeholder="Days Supply"
          className="daysSuppy"
          value={curDaysSupply}
        />

        <div className="">
          <label
            className="med-prescribe-section-label"
            htmlFor="allowSubstitution"
          >
            Allow Substitution:
          </label>
          <input
            type="checkbox"
            name="allowSubstitution"
            onChange={handleChange}
            className="allowSub"
            checked={curAllowSubState}
          />
          <br />
        </div>

        <div className="med-prescribe-input-holder">
          <label className="med-prescribe-section-label">Directions:</label>

          <textarea
            name="directions"
            onChange={handleChange}
            placeholder="Input directions for patient here"
            className="directions"
            type="text"
            value={curDirections}
          />
        </div>
        <div className="med-prescribe-input-holder">
          <label className="med-prescribe-section-label">
            Pharmacy Notes:
            <div className="med-prescribe-section-sublabel">(Optional)</div>
          </label>

          <textarea
            name="pharmacyNotes"
            onChange={handleChange}
            placeholder="Input notes for patient here"
            className="pharmacy-note"
            type="text"
            value={curPharmacyNotes}
          />
        </div>

        {!noButton && (
          <button
            data-test-id="addMedButton"
            disabled={!enableSaveButton}
            className={`visitButton addMedButton${buttonSavingClassName}`}
            onClick={confirmMedicine}
          >
            {isSaving ? <div className="loader"></div> : confirmButtonName}
          </button>
        )}
      </div>
    </>
  );
};

PrescriptionForm.propTypes = {
  currentMedicine: PropTypes.object.isRequired,
  setCurrentMedicine: PropTypes.func.isRequired,
  savePrescription: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default PrescriptionForm;
