import React, {
  // useEffect,
  useState,
} from "react";

import bigLogo from "../../Assets/bigLogo.png";

import { routerMap } from "../../utils/router";

import "./Login.css";

const Login = (props) => {
  const { getUserInfo, isCheckingLogin } = props;
  const [loginData, setLoginData] = useState({});
  const [message, setMessage] = useState("");
  // const [isVerify, setIsVerify] = useState(false);
  // const [userId, setUserId] = useState(null);

  const token = localStorage.getItem("jwt");

  const {
    login,
    // descopeVerify,
    // descopeSignin,
    // getUser
  } = routerMap;

  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(token, e, loginData, setMessage, getUserInfo);
  };

  // const handleDescopeSignin = async () => {
  //   await descopeSignin(loginData, setIsVerify);
  // };

  // const handleDescopeVerify = async () => {
  //   await descopeVerify(loginData, setUserId);
  // };

  // useEffect(() => {
  //   if(userId) {
  //     const asyncLogin = async () => {
  //       await getUser(userId, getUserInfo);
  //     };
  //     asyncLogin();
  //   }
  // }, [userId]);

  return (
    <div className="loginContainer">
      <div className="middleRectangle">
        <div className="logoContainer">
          <img src={bigLogo} alt="Beluga Health Logo" className="loginLogo" />
        </div>
        {message}
        {isCheckingLogin ? (
          <div className="loginText">Checking if you are logged in...</div>
        ) : (
          <form className="loginForm" onSubmit={handleSubmit}>
            <div className="loginText">Log In</div>
            <input name="email" placeholder="Email" onChange={handleChange} />
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
            />
            <button type="submit" className="loginButton">
              Log In
            </button>
          </form>
        )}
        {/* {isVerify ?
            <>
              <input
                name="code"
                value={loginData.code || ''}
                placeholder="Verify Code"
                onChange={handleChange}
              />
              <button
                className="loginButton"
                onClick={handleDescopeVerify}
              >
                Verify
              </button>
            </>
          :
            <>
              <input
                name="email"
                value={loginData.email || ''}
                placeholder="Email"
                onChange={handleChange}
              />
              <button
                className="loginButton"
                onClick={handleDescopeSignin}
              >
                Descope
              </button>
            </>
          } */}
      </div>
    </div>
  );
};

export default Login;
