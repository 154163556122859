import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { routerMap } from "./utils/router";

import Login from "./Auth/Login/Login";
import MyAccount from "./MyAccount/MyAccount";
import Patient from "./Patient/Patient";
import Favorites from "./Favorites/Favorites";
import Companies from "./Companies/Companies";
import Header from "./Header/Header";
// import { NotificationsProvider } from "./Components/notifications/context/notificationsContext";

import "./App.css";

const App = () => {
  const [user, setUser] = useState({});
  const [admin, setAdmin] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isCheckingLogin, setIsCheckingLogin] = useState(false);
  const [currentPatient, setCurrentPatient] = useState({});
  const [dsUrl, setDsUrl] = useState("");
  const [transErrors, setTransErrors] = useState(0);
  const [companies, setCompanies] = useState([]);

  const token = localStorage.getItem("jwt");
  const sessionToken = sessionStorage.getItem("jwt");

  const navigate = useNavigate();

  const { logout, checkLogin } = routerMap;

  const getUserInfo = async (userInfo) => {
    setUser(userInfo);
    setLoggedIn(true);
    navigate("/myaccount");
  };

  const handleLogout = async () => {
    await logout(token, setUser, setLoggedIn, setAdmin, navigate);
  };

  const handleOnIdle = async () => {
    if (loggedIn) await handleLogout();
  };

  const handleCheckLogin = async () => {
    await checkLogin(
      token,
      sessionToken,
      setIsCheckingLogin,
      setUser,
      navigate,
      getUserInfo
    );
  };

  const DEFAULT_EVENTS = [
    "keydown",
    "wheel",
    "DOMMouseScroll",
    "mouseWheel",
    "mousedown",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove",
    "visibilitychange",
  ];

  const inactiveCompanies = [
    "scoreBlue",
    "sprout",
    "metuchen",
    "revyv",
    "rxCompoundStore",
    "powerplate",
    "medsForMen",
    "oneHealthLabs",
    "sunrise",
  ];

  const onlyActiveCompanies = companies.filter(
    (company) => !inactiveCompanies.includes(company.name)
  );
  // check for idle user every 15 minutes
  useIdleTimer({
    // timeout: 15,
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500,
    startOnMount: false,
    events: DEFAULT_EVENTS,
  });

  useEffect(() => {
    const fetchLogin = async () => {
      await handleCheckLogin();
    };
    fetchLogin();
  }, []);

  useEffect(() => {
    if (Object.keys(currentPatient).length > 0) {
      navigate(`/patient/${currentPatient._id}`);
    }
  }, [currentPatient]);

  return (
    <div className="App">
      {loggedIn && (
        <Header
          user={user}
          setUser={setUser}
          logout={handleLogout}
          admin={admin}
          setAdmin={setAdmin}
          currentPatient={currentPatient}
          setCurrentPatient={setCurrentPatient}
          companies={onlyActiveCompanies}
        />
      )}
      <Routes>
        {!loggedIn ? (
          <Route
            path="/"
            element={
              <Login
                getUserInfo={getUserInfo}
                isCheckingLogin={isCheckingLogin}
              />
            }
          />
        ) : (
          <>
            <Route
              exact
              path="/myaccount"
              element={
                <MyAccount
                  user={user}
                  admin={admin}
                  setAdmin={setAdmin}
                  currentPatient={currentPatient}
                  setCurrentPatient={setCurrentPatient}
                  checkLogin={handleCheckLogin}
                  dsUrl={dsUrl}
                  setDsUrl={setDsUrl}
                  transErrors={transErrors}
                  setTransErrors={setTransErrors}
                  companies={companies}
                  setCompanies={setCompanies}
                />
              }
            />
            <Route
              path="/patient/:id"
              element={
                // <NotificationsProvider>
                <Patient admin={admin} user={user} patient={currentPatient} />
                // </NotificationsProvider>
              }
            />
            <Route
              path="/favourites"
              element={
                <Favorites
                  user={user}
                  admin={admin}
                  companies={onlyActiveCompanies.map((company) => company.name)}
                />
              }
            />
            <Route
              path="/companies"
              element={
                <Companies
                  user={user}
                  admin={admin}
                  companies={onlyActiveCompanies.sort((a, b) =>
                    a.name.localeCompare(b.name)
                  )}
                  setCompanies={setCompanies}
                />
              }
            />
          </>
        )}
      </Routes>
    </div>
  );
};

export default App;
